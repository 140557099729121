<template>
  <b-modal
    size="xl"
    v-model="internalShowModal"
    @close="handleClose"
    :body-class="bodyClass"
    @cancel="handleClose"
    :ok-variant="okVariant"
    :cancel-variant="cancelVariant"
  >
    <div class="" v-if="quote && getVersionQuote">
      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col>
            <QuoteListVersionsComponent
              :quote="quote"
              @onClickVersion="handleOnClickVersion"
            />
          </b-col>
        </b-row>
        <div class="card-quotes-view-header">
          <b-row>
            <b-col xl="7">
              <div class="card-quotes-view-logo mb-4">
                <b-row>
                  <b-col md="6" xl="4">
                    <div class="logo">
                      <img
                        v-if="getUser.user_business.business_logo"
                        :src="getUser.user_business.business_logo"
                        class="image"
                        id="image-business"
                        alt="Empresa"
                        width="200px"
                      />
                    </div>
                  </b-col>
                  <b-col md="6" xl="8">
                    <div class="info">
                      <p class="mb-2 text-color-secondary font-bold">
                        Versión: {{ getVersionQuote.version_number }}
                      </p>
                      <h4 class="h4 font-bold m-0">N° {{ quote.code }}</h4>
                      <h2 class="h2 font-bold m-0">
                        {{ getVersionQuote.name }}
                      </h2>
                      <p class="font-semi-bold mt-2">
                        RUC: {{ getUser.user_business.business_ruc }}
                      </p>
                      <p class="font-semi-bold">
                        {{ getUser.user_business.business_address }}
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col xl="5">
              <div class="card-quotes-view-info">
                <b-row>
                  <b-col xs="12" sm="4" md="4" lg="4">
                    <label class="font-semi-bold d-block">Emisión</label>
                    <p class="m-0">{{ getVersionQuote.date_at }}</p>
                  </b-col>
                  <b-col xs="12" sm="4" md="4" lg="4">
                    <label class="font-semi-bold d-block">Validez</label>
                    <p class="m-0">{{ getVersionQuote.validate_at }}</p>
                  </b-col>
                  <b-col xs="12" sm="4" md="4" lg="4">
                    <label class="font-semi-bold d-block mb-2">Status</label>
                    <StatusReadDocumentsComponent :status="quote.status" />
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col xs="12" sm="4" md="4" lg="4">
                    <label class="font-semi-bold d-block">Contacto</label>
                    <p class="m-0">{{ contactName }}</p>
                  </b-col>
                  <b-col xs="12" sm="4" md="4" lg="4">
                    <label class="font-semi-bold d-block">Dirección</label>
                    <p class="m-0">{{ quote.client.address }}</p>
                  </b-col>
                  <b-col xs="12" sm="4" md="4" lg="4">
                    <label class="font-semi-bold d-block"
                      >Teléfono Contacto</label
                    >
                    <p class="m-0">
                      Telf: {{ getUser.user_business.business_phone }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="card-quotes-view-body">
          <div class="card-quotes-view-body-products">
            <div class="card-quotes-view-table table-items">
              <b-row class="table-items-header">
                <b-col xs="12" sm="12" md="1">
                  <h6 class="h6 font-bold mb-0 ml-2">Código</h6>
                </b-col>
                <b-col xs="12" sm="12" md="5">
                  <h6 class="h6 font-bold mb-0">Detalle</h6>
                </b-col>
                <b-col xs="12" sm="4" md="2">
                  <h6 class="h6 font-bold mb-0">Cantidad</h6>
                </b-col>
                <b-col xs="12" sm="4" md="2">
                  <h6 class="h6 font-bold mb-0">Precio U.</h6>
                </b-col>
                <b-col xs="12" sm="4" md="2">
                  <h6 class="h6 font-bold mb-0">Subtotal</h6>
                </b-col>
              </b-row>
            </div>

            <b-row
              class="mt-4 d-flex"
              v-for="(product, index) in getVersionQuote.products"
              :key="index"
            >
              <b-col xs="12" sm="12" md="2">
                <p class="m-0 ml-2 text-break">{{ product.product.code }}</p>
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <p class="m-0">
                  {{ product.product.name }}<br />
                  <br />
                  <span
                    v-html="product.product_detail"
                    class="text-pre-wrap"
                  ></span>
                </p>
              </b-col>
              <b-col xs="12" sm="4" md="2">
                <p class="m-0">{{ product.product_quantity }}</p>
              </b-col>
              <b-col xs="12" sm="4" md="2">
                <p class="m-0">${{ product.product_amount }}</p>
              </b-col>
              <b-col xs="12" sm="4" md="2">
                <p class="m-0">
                  ${{
                    handleCalculateSubTotal(
                      product.product_quantity,
                      product.product_amount
                    )
                  }}
                </p>
              </b-col>
            </b-row>
          </div>
          <hr />
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="6">
              <div class="card-quotes-view-aditionals bg-color-gray p-4">
                <p class="font-semi-bold">Especificaciones Adicionales</p>
                <b-row
                  class="my-2"
                  v-for="(specification,
                  index) in getVersionQuote.additional_specifications"
                  :key="index"
                >
                  <b-col xs="10" sm="10" md="10">- {{ specification }}</b-col>
                </b-row>
              </div>
              <div
                class="card-quotes-view-method-pay mt-4 p-4 d-flex align-items-baseline"
              >
                <p class="font-semi-bold mr-4">Forma de pago</p>
                <section v-if="getVersionQuote.method_payment === 1">
                  <el-radio v-model="getVersionQuote.method_payment" :label="1"
                    >De contado</el-radio
                  >
                </section>

                <section v-if="getVersionQuote.method_payment === 2">
                  <el-radio v-model="getVersionQuote.method_payment" :label="2"
                    >Crédito
                  </el-radio>
                  <p class="mt-2">
                    {{ getLimitCredit.label }}
                    {{ getVersionQuote.limit_credit_at }}
                  </p>
                </section>

                <section v-if="getVersionQuote.method_payment === 3">
                  <el-radio v-model="getVersionQuote.method_payment" :label="3"
                    >Anticipo / Contra entrega
                  </el-radio>
                  <p class="mt-2">
                    {{ getVersionQuote.observation_advance }}
                  </p>
                </section>
              </div>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="4" offset-lg="2">
              <b-card class="bg-color-card">
                <DocumentCalculator
                  readOnly
                  :setProducts="getVersionQuote.products"
                  :setDisc="getVersionQuote.discount"
                />
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.amount {
  p {
    text-align: center;
    font-size: 1.5rem !important;
    font-weight: bold;
  }

  label {
    text-align: center;
    font-size: 1.2rem !important;
  }
}

.bg-color-card {
  background: #f6f9ff;
}
</style>

<script>
import { parseFloatFormat } from "@/assets/utils/format";
import quoteService from "../services/quoteService";
import { limitCredits } from "../assets/resources";
import DocumentCalculator from "../../../components/DocumentCalculator.vue";
import QuoteListVersionsComponent from "../components/QuoteListVersions";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";

export default {
  name: "QuotePreviewComponent",
  data() {
    return {
      apiResponse: {
        success: false,
        message: null
      },
      internalShowModal: false,
      okVariant: "btn md__btn-primary",
      cancelVariant: "btn md__btn-secondary",
      bodyClass: "modal-custom",
      limitCredits,
      quoteVersion: null
    };
  },
  props: {
    quote: {
      require: true
    },
    idVersion: {
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    showModal(newVal) {
      // Actualiza la propiedad de datos cuando cambia la prop
      this.internalShowModal = newVal;
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    getLimitCredit() {
      return limitCredits.find(
        limit => this.getVersionQuote.limit_credit === limit.value
      );
    },
    getVersionQuote() {
      return this.quote.versions.find(v => this.idVersion === v.id);
    },
    contactName() {
      return this.quote.contact
        ? this.quote.contact.name
        : this.quote.client.name;
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    handleCalculateSubTotal(quantity, price) {
      return parseFloatFormat(quantity * price);
    },
    handleClose() {
      this.$emit("close");
    },
    goPage(name) {
      this.$router.push({ name });
    },
    handleOnClickVersion(version) {
      this.idVersion = version.id;
    },
    async getQuote(quoteId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService.getQuoteId(quoteId).finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { success, quote } = response.data;
        if (success) {
          this.quote = quote;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    DocumentCalculator,
    QuoteListVersionsComponent,
    StatusReadDocumentsComponent
  }
};
</script>
