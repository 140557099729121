<template>
  <el-popover placement="bottom-start" width="350" trigger="hover">
    <template name="—">
      <p class="px-2 m-0 mb-4 font-bold text-color-blank">
        Visibilidad de documentos
      </p>
      <el-checkbox
        v-model="is_sales_quotes_visibility"
        class="d-block"
        @change="
          v =>
            onChangeCheckboxVisiility(
              is_sales_quotes_visibility,
              'is_sales_quotes_visibility'
            )
        "
        >Cotizaciones</el-checkbox
      >
      <el-checkbox
        v-model="is_sales_invoices_visibility"
        class="d-block"
        @change="
          v => onChangeCheckboxVisiility(v, 'is_sales_invoices_visibility')
        "
        >Facturas</el-checkbox
      >
      <el-checkbox
        v-model="is_sales_liquidations_visibility"
        class="d-block"
        @change="
          v => onChangeCheckboxVisiility(v, 'is_sales_liquidations_visibility')
        "
        >Liquidaciones de Compras</el-checkbox
      >
      <el-checkbox
        v-model="is_sales_credit_notes_visibility"
        class="d-block"
        @change="
          v => onChangeCheckboxVisiility(v, 'is_sales_credit_notes_visibility')
        "
        >Notas créditos</el-checkbox
      >
      <el-checkbox
        v-model="is_sales_debit_notes_visibility"
        class="d-block"
        @change="
          v => onChangeCheckboxVisiility(v, 'is_sales_debit_notes_visibility')
        "
        >Notas débito</el-checkbox
      >
      <el-checkbox
        v-model="is_sales_withholdings_visibility"
        class="d-block"
        @change="
          v => onChangeCheckboxVisiility(v, 'is_sales_withholdings_visibility')
        "
        >Retenciones</el-checkbox
      >

      <el-checkbox
        v-model="is_sales_payments_visibility"
        class="d-block"
        @change="
          v => onChangeCheckboxVisiility(v, 'is_sales_payments_visibility')
        "
        >Cobros</el-checkbox
      >
    </template>
    <img slot="reference" :src="icoFilter" alt="filter" width="28" />
  </el-popover>
</template>

<script>
import { icoFilter } from "@/services/resources";

export default {
  name: "FilterDocumentShowComponent",
  data() {
    return {
      icoFilter,
      is_sales_quotes_visibility: true,
      is_sales_invoices_visibility: true,
      is_sales_credit_notes_visibility: true,
      is_sales_debit_notes_visibility: true,
      is_sales_withholdings_visibility: true,
      is_sales_payments_visibility: true,
      is_sales_liquidations_visibility: true
    };
  },
  computed: {
    userConfigGeneral() {
      return this.$store.getters.getUser.user_config_general;
    }
  },
  methods: {
    onChangeCheckboxVisiility(value, key) {
      const userConfigGeneral = {};
      userConfigGeneral[key] = value;
      this.$store.dispatch("userConfigGeneralUpdated", userConfigGeneral);
    }
  },
  mounted() {
    const {
      is_sales_quotes_visibility = true,
      is_sales_invoices_visibility = true,
      is_sales_credit_notes_visibility = true,
      is_sales_debit_notes_visibility = true,
      is_sales_withholdings_visibility = true,
      is_sales_liquidations_visibility = true,
      is_sales_payments_visibility = true
    } = this.userConfigGeneral;
    this.is_sales_quotes_visibility = is_sales_quotes_visibility;
    this.is_sales_invoices_visibility = is_sales_invoices_visibility;
    this.is_sales_credit_notes_visibility = is_sales_credit_notes_visibility;
    this.is_sales_debit_notes_visibility = is_sales_debit_notes_visibility;
    this.is_sales_withholdings_visibility = is_sales_withholdings_visibility;
    this.is_sales_payments_visibility = is_sales_payments_visibility;
    this.is_sales_liquidations_visibility = is_sales_liquidations_visibility;
  }
};
</script>

<style lang="scss" scoped></style>
