<template>
  <v-tour name="tour" :steps="steps" :options="options" :callbacks="callbacks">
  </v-tour>
</template>

<script>
export default {
  name: "SalesTourComponent",
  data() {
    return {
      callbacks: {
        onSkip: this.tourStepFinish,
        onFinish: this.tourStepFinish
      },
      options: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Cerrar",
          buttonPrevious: "Anterior",
          buttonNext: "Continuar",
          buttonStop: "Finalizar"
        }
      },
      steps: [
        {
          target: "#app",
          content: `
            <h5 class="font-bold">1/3</h5>
            <h6 class="text-color-secondary font-bold">Crea tu primera cotización</h6>
            <p class="m-0">Aquí podras agregar las cotizaciones para tus clientes, puedes filtraras por estado y ver las versiones de cada una.</p>
          `,
          params: {
            placement: "top"
          }
        },
        {
          target: "#app",
          content: `
            <h5 class="font-bold">2/3</h5>
            <h6 class="text-color-secondary font-bold">Configura tu Firma electrónica y comienza a facturar</h6>
            <p class="m-0">Gestiona todos tus documentos electrónicos y emite facturas a tus clientes.</p>
          `,
          params: {
            placement: "top"
          }
        },
        {
          target: "#app",
          content: `
            <h5 class="font-bold">3/3</h5>
            <h6 class="text-color-secondary font-bold">Realiza cobros a tus clientes, genera un link de cobro y lleva un control de tus ingresos y cuentas por cobrar.</h6>
            <p class="m-0">Gestiona todos tus documentos electrónicos y emite facturas a tus clientes.</p>
          `,
          params: {
            placement: "top"
          }
        }
      ]
    };
  },
  computed: {},
  methods: {
    tourStepFinish() {
      const userConfigGeneral = {
        is_sales_tour_completed: 1
      };
      this.$store.dispatch("userConfigGeneralUpdated", userConfigGeneral);
    }
  },
  components: {},
  mounted() {
    this.$tours["tour"].start();
  }
};
</script>

<style lang="scss" scoped></style>
