import Api from "@/config/Api";

export default {
  getUserConfigSmtp() {
    return Api().get("/me/config/smtp", {});
  },

  updateUserConfigSmtp(data) {
    return Api().post("/me/config/smtp", data);
  },

  getUserConfigTexts() {
    return Api().get("/me/config/texts", {});
  },

  updateUserConfigTexts(data) {
    return Api().post("/me/config/texts", data);
  },

  getUserConfigEmail() {
    return Api().get("/me/config/email", {});
  },

  updateUserConfigEmail(data) {
    return Api().post("/me/config/email", data);
  }
};
