<template>
  <a href="javascript:;" class="font-bold" :class="classNames" @click="onClick">
    <span class="pr-2" v-if="title">{{ title }}</span>
    <i class="el-icon-right h4" v-if="showIcon"></i>
  </a>
</template>

<script>
export default {
  name: "LinkComponent",
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  },
  props: {
    title: {
      default: ""
    },
    classNames: {
      default: ""
    },
    showIcon: {
      default: true
    }
  }
};
</script>

<style></style>
