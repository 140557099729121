<template>
  <div class="tag-input">
    <div v-for="(tag, index) in tags" :key="index" class="tag-input__tag">
      {{ tag }}
      <span @click="removeTag(index)">x</span>
    </div>
    <input
      type="text"
      placeholder=""
      class="tag-input__text text-color-black"
      @keydown.enter="addTag"
      @keydown.tab="addTag"
      @blur="addTag"
    />
  </div>
</template>
<script>
export default {
  name: "TagInputComponent",
  data() {
    return {
      tags: []
    };
  },
  methods: {
    addTag(event) {
      const emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      event.preventDefault();
      var val = event.target.value.trim();
      if (val.length > 0) {
        if (emailRegex.test(val)) {
          this.tags.push(val);
          event.target.value = "";
          this.onSeletedTags();
        }
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
      this.onSeletedTags();
    },
    onSeletedTags() {
      this.$emit("onChangeTags", this.tags);
    }
  },
  props: {
    values: {
      type: Array,
      default: null
    }
  },
  mounted() {
    if (this.values) this.tags = this.values;
  }
};
</script>
<style scoped>
.tag-input {
  width: 100%;
  border-bottom: 1px solid #eee;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
}

.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 10px;
  background-color: #f4f4f5;
  border: solid 1px #e9e9eb;
  color: #909399;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
  border-radius: 50px;
  font-size: 12px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9em;
  line-height: 50px;
  background: none;
}
</style>
