<template>
  <div>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        <IconMoreOptionComponent size="small" theme="blue" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="quote-preview">
          <img :src="icoPreview" alt="" /> Previsualizar
        </el-dropdown-item>

        <template v-if="status === 'draft' || status === 'pending'">
          <el-dropdown-item command="quote-convert-invoice">
            <img :src="icoInvoice" alt="" /> Convertir en factura
          </el-dropdown-item>
          <el-dropdown-item command="quote-edit">
            <img :src="iconEdit" alt="" /> Editar
          </el-dropdown-item>
          <el-dropdown-item command="quote-delete">
            <img :src="iconDelete" alt="" /> Eliminar
          </el-dropdown-item>
        </template>
        <el-dropdown-item command="quote-send">
          <img :src="iconSend" alt="" /> Enviar documento
        </el-dropdown-item>
        <el-dropdown-item command="quote-download">
          <img :src="iconDownload" alt="" class="img-sm" /> Descargar PDF
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <WrapperSendDocumentDrawer
      @onDocumentSend="onDocumentSend"
      @onCloseDrawer="onCloseDocumentSendDrawer"
      :visibleDocumentSend="visibleDocumentSend"
      :isLoadingConfigTexts="false"
    />
    <Preview
      :quote="quote"
      :idVersion="idVersion"
      :showModal="quotePreview"
      @close="handleCommand('quote-preview')"
    />
  </div>
</template>
<style lang="scss" scoped>
.img-sm {
  width: 0.8rem !important;
}
</style>
<script>
import IconMoreOptionComponent from "@/components/General/IconMoreOption";
import quoteService from "../services/quoteService";
import Preview from "./QuotePreview.vue";
import WrapperSendDocumentDrawer from "../../../../components/WrapperSendDocumentDrawer";

import { downloadFile } from "@/assets/utils/files";
import {
  icoNotifyDanger,
  iconEdit,
  iconDelete,
  iconNuled,
  iconSend,
  iconDownload,
  iconCheck,
  icoPreview,
  icoInvoice
} from "@/services/resources";

export default {
  data() {
    return {
      icoNotifyDanger,
      iconEdit,
      iconDelete,
      iconNuled,
      iconSend,
      iconDownload,
      iconCheck,
      icoPreview,
      icoInvoice,
      quote: null,
      quotePreview: false,
      visibleDocumentSend: false,
      visibleDocumentHistoryEmail: false
    };
  },
  props: ["status", "id", "idVersion"],
  computed: {
    toMails() {
      // const { client, contact } = this.quote;
      // if (contact && contact.email) return [contact.email];
      // else if (client && client.email) return [client.email];
      return [];
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },

    async getQuote(quoteId) {
      try {
        let response = await quoteService.getQuoteId(quoteId).finally(() => {});
        const { success, quote } = response.data;
        if (success) {
          this.quote = quote;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    },

    handleCommand(command) {
      if (this.quote == null) {
        this.getQuote(this.id);
      }

      switch (command) {
        case "quote-preview":
          this.quotePreview = !this.quotePreview;
          break;
        case "quote-edit":
          this.$emit("goPage", "sales.quotes.edit", {
            id: this.id,
            idVersion: this.idVersion
          });
          break;
        case "quote-send":
          this.visibleDocumentSend = true;
          break;
        case "quote-convert-invoice":
          this.$emit("sendDoc");
          break;
        case "quote-history-email":
          this.visibleDocumentHistoryEmail = true;
          break;
        case "quote-download":
          this.quoteDownload();
          break;
        case "quote-delete":
          this.quoteDelete();
          break;
        case "quote-change-status-pending":
          this.updateQuoteStatus("pending");
          break;
        case "quote-change-status-deny":
          this.updateQuoteStatus("deny");
          break;
      }
    },

    onCloseDocumentSendDrawer() {
      this.visibleDocumentSend = false;
    },
    onCloseDocumentSendEmailHistory() {
      this.visibleDocumentHistoryEmail = false;
    },
    onDocumentSend({ data }) {
      data.append("quote_id", this.quote.id);
      data.append("quote_version_id", this.idVersion);

      this.sendQuoteEmail({ data });
    },
    async quoteDownload() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService
          .downloadQuote({
            quoteId: this.id,
            quoteVersionId: this.idVersion
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });

        if (response) {
          downloadFile(response.data, `Cotización - #${this.quote.code}`);
        }
      } catch (error) {
        return false;
      }
    },

    async quoteDelete() {
      try {
        let response = await quoteService
          .deleteQuoteId(this.id)
          .finally(() => {});

        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });

          this.$store.dispatch("sendNotification", true);
        }
      } catch (error) {
        return false;
      }
    },

    async sendQuoteEmail({ data }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService
          .sendQuoteEmail({
            data
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data: quote } = response.data;
        if (success) {
          this.quote = quote;
          this.visibleDocumentSend = false;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    IconMoreOptionComponent,
    Preview,
    WrapperSendDocumentDrawer
  }
};
</script>
