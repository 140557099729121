<template>
  <div class="wrapper__content-sale-document">
    <div class="sale-document-header d-flex justify-content-between align-items-center px-2">
      <h4 class="h4 text-color-black">Cotizaciones</h4>
      <div class="icons">
        <ButtonCirclePlusComponent @onClick="quoteAdd" />
        <ButtonFilterComponent @onChangeFilters="onChangeFilters" @onApplyFilters="onApplyFilters"
          :setFilters="searchFilters" :loading="loading" :setStatus="filterStatus"
          placeholder="Buscar por número documento" isStatus isSearch isOrderBy isDates isClients class="mx-2" />
      </div>
    </div>
    <template>
      <WrapperScrollbarComponent height="90vh" @scrollingEnd="infiniteHandler">
        <QuoteCardComponent v-for="(quote, index) in filterQuotes" :key="index" :quote="quote"/>
        <infinite-loading @distance="90" @infinite="infiniteHandler" ref="infiniteLoading">
          <div slot="spinner">
            <SkeletonOneCardComponent />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results">
            <QuoteEmptyComponent v-if="!quotes.length" />
          </div>
        </infinite-loading>
      </WrapperScrollbarComponent>
    </template>
  </div>
</template>

<script>
import ButtonCirclePlusComponent from "../../../components/ButtonCirclePlus";
import ButtonFilterComponent from "../../../components/ButtonFilter";
import QuoteEmptyComponent from "./Empty";
import QuoteCardComponent from "./QuoteCard";
import SkeletonOneCardComponent from "@/components/Animations/Loading/SkeletonOneCard";
import WrapperScrollbarComponent from "../../../../components/WrapperScrollbar";

import quoteService from "../services/quoteService";

export default {
  name: "QuoteColumnsComponent",
  data() {
    return {
      loading: true,
      searchFilters: null,
      filterStatus: [
        {
          label: "Borrador",
          key: "draft"
        },
        {
          label: "Pendiente",
          key: "pending"
        },
        {
          label: "Aprobada",
          key: "success"
        },
        {
          label: "No aprobada",
          key: "deny"
        }
      ],
      page: 1,
      quotes: [],
      breakScroll: false,
      debounceTimer: null
    };
  },
  watch: {
    notication: {
      handler() {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.onAsyncDataForDates();
        }, 200);
      },
      immediate: true
    }
  },
  computed: {
    notication() {
      return this.$store.getters.getNotification;
    },
    filterOrderBy() {
      const { orderBy } = this.searchFilters;
      return orderBy;
    },
    requestFilters() {
      const { search, from, to, status, clientId: client } = this.searchFilters;
      return { search, from, to, status, client };
    },
    filterQuotes() {
      return this.quotes.concat().sort((a, b) => {
        const versionA = a.versions.find((v, index) => index <= 0);
        const versionB = b.versions.find((v, index) => index <= 0);
        if (this.filterOrderBy && this.filterOrderBy === "desc") {
          return versionA.total > versionB.total ? -1 : 1;
        } else if (this.filterOrderBy && this.filterOrderBy === "asc") {
          return versionA.total < versionB.total ? -1 : 1;
        }
      });
    },
    getQuotesFilters() {
      const { documents } = this.$store.getters.getModule("sales");
      return documents.quotes.filters.get;
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    quoteAdd() {
      this.goRouter("sales.quotes.add");
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };

      this.$store.dispatch("salesDocumentsFiltersGet", {
        documentKey: "quotes",
        filters: this.searchFilters
      });
    },
    onChangeFilters(filters) {
      this.updatedSearchFilters(filters);
    },
    onApplyFilters() {
      this.onAsyncDataForDates();
    },
    onAsyncDataForDates() {
      this.quotes = [];
      this.page = 1;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.$store.dispatch("sendNotification", false);
    },
    async getQuotes($state) {
      try {
        this.loading = true;
        let response = await quoteService
          .getQuotes({
            params: {
              ...{
                page: this.page,
                client: this.getClientId,
                limit: 0
              },
              ...this.requestFilters
            }
          })
          .finally(() => {
            this.breakScroll = false;
            this.loading = false;
          });
        const { quotes } = response.data;
        if (quotes.length) {
          if (this.page === 1) {
            this.quotes = [...this.quotes, ...quotes];
          } else {
            quotes.forEach(item => this.quotes.push(item));
          }
          this.page = this.page + 1;
        }

        $state.complete();
      } catch (error) {
        return false;
      }
    },
    infiniteHandler($state) {
      if (this.breakScroll) return false;
      this.breakScroll = true;
      this.getQuotes($state);
    }
  },
  components: {
    ButtonCirclePlusComponent,
    ButtonFilterComponent,
    QuoteEmptyComponent,
    QuoteCardComponent,
    SkeletonOneCardComponent,
    WrapperScrollbarComponent
  },
  props: {
    getClientId: {
      default: null
    }
  },
  mounted() {
    this.searchFilters = this.getQuotesFilters;
  }
};
</script>

<style lang="scss" scoped></style>
