<template>
  <el-tooltip :content="content" :placement="placement">
    <slot></slot>
  </el-tooltip>
</template>

<script>
export default {
  name: "WrapperTooltipComponent",
  props: {
    content: {
      default: ""
    },
    placement: {
      default: "top"
    }
  }
};
</script>
<style lang="scss" scoped></style>
