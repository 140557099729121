<template>
  <LinkComponent
    :showIcon="false"
    title="Portal del SRI"
    classNames="text-color-secondary"
    @onClick="redirectPortalSRI"
  />
</template>

<script>
import LinkComponent from "@/modules/dashboard/components/Link";

export default {
  components: {
    LinkComponent
  },
  methods: {
    redirectPortalSRI() {
      window.open("https://srienlinea.sri.gob.ec/sri-en-linea/", "_blank");
    }
  }
};
</script>

<style></style>
