<template>
  <el-collapse v-model="activeNames" class="collapse-quote-list-versions">
    <el-collapse-item name="1" class="p-2 my-4 bg-color-write">
      <template slot="title">
        <p class="font-bold">
          <img class="mr-1" :src="icoVersions" alt="" /> Versiones Anteriores
          <span class="text-color-primary ml-4 font-bold"
            >({{ quote.versions.length }})</span
          >
        </p>
      </template>
      <div
        class="wrapper__quote-version line-bottom px-2 py-4 transition-shadow border-radius"
        :class="{ active: version.id === versionActived }"
        v-for="(version, index) in quote.versions"
        :key="index"
        @click="quoteView(version)"
      >
        <b-row>
          <b-col xs="12" sm="12" md="2">
            <div class="quote-version-number">
              <p class="m-0 p-0 text-color-secondary font-bold">
                Versión: {{ version.version_number }}
              </p>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <div class="quote-version-name">
              <p class="m-0 p-0 text-color-primary font-bold str-limit-space">
                {{ version.name }}
              </p>
            </div>
          </b-col>
          <b-col xs="6" sm="6" md="2">
            <div class="quote-version-date">
              <p class="m-0 p-0 text-color-primary font-bold">
                {{ version.created_at }}
              </p>
            </div>
          </b-col>
          <b-col xs="6" sm="6" md="2">
            <div class="quote-version-total">
              <p class="m-0 p-0 text-color-primary font-bold">
                ${{ version.total }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { icoVersions } from "@/services/resources";

export default {
  name: "QuoteListVersionsComponent",
  data() {
    return {
      icoVersions,
      activeNames: [],
      versionActived: null
    };
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    quoteView(version) {
      this.versionActived = version.id;
      this.$emit("onClickVersion", version);
      this.goRouter("sales.quotes.view", {
        id: this.quote.id,
        idVersion: version.id
      });
    }
  },
  props: {
    quote: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper__quote-version {
  cursor: pointer;

  &.active {
    background: #f7f8f9;
  }
}
</style>
