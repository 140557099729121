import moment from "moment";

export const years = () => {
  const years = [];
  const currentYear = moment().format("YYYY");
  for (let index = 2020; index <= currentYear; index++) {
    years.push(index);
  }
  return years;
};
export const currentYear = moment().format("YYYY");
export const currentDayDate = moment().format("YYYY-MM-DD");

export const lastWeekFromDate = moment()
  .subtract(7, "days")
  .startOf("week")
  .format("YYYY-MM-DD");

export const currentFromDate = moment()
  .startOf("month")
  .format("YYYY-MM-DD");
export const currentToDate = moment()
  .endOf("month")
  .format("YYYY-MM-DD");

export const lastFromMonthDate = moment()
  .subtract(1, "months")
  .startOf("month")
  .format("YYYY-MM-DD");
export const lastToMonthDate = moment()
  .subtract(1, "months")
  .endOf("month")
  .format("YYYY-MM-DD");

export const lastYearDate = moment()
  .startOf("year")
  .format("YYYY-MM-DD");

export const lastFromYearDate = moment()
  .subtract(1, "years")
  .startOf("year")
  .format("YYYY-MM-DD");
export const lastToYearDate = moment()
  .subtract(1, "years")
  .endOf("year")
  .format("YYYY-MM-DD");

export const filterDates = [
  {
    label: "currentDay",
    title: "Hoy"
  },
  {
    label: "lastWeek",
    title: "Última semana"
  },
  {
    label: "currentMonth",
    title: "Mes actual"
  },
  {
    label: "lastMonth",
    title: "Mes anterior"
  },
  {
    label: "currentYear",
    title: "Último año"
  },
  {
    label: "lastYear",
    title: "Año anterior"
  },
  {
    label: "dateRages",
    title: "Rango de fechas"
  }
];

export const months = [
  {
    value: 1,
    label: "Enero"
  },
  {
    value: 2,
    label: "Febrero"
  },
  {
    value: 3,
    label: "Marzo"
  },
  {
    value: 4,
    label: "Abril"
  },
  {
    value: 5,
    label: "Mayo"
  },
  {
    value: 6,
    label: "Junio"
  },
  {
    value: 7,
    label: "Julio"
  },
  {
    value: 8,
    label: "Agosto"
  },
  {
    value: 9,
    label: "Septiembre"
  },
  {
    value: 10,
    label: "Octubre"
  },
  {
    value: 11,
    label: "Noviembre"
  },
  {
    value: 12,
    label: "Diciembre"
  }
];

export const DATE_AT = moment().format("YYYY-MM-DD");
export const YEAR_DATE_AT = parseInt(moment().format("YYYY"));
export const MONTH_DATE_AT = parseInt(moment().format("M"));
