<template>
  <el-button
    type="text"
    :disabled="disabled"
    :class="`${classNames}`"
    @click="onClick"
    >{{ text }}</el-button
  >
</template>

<script>
export default {
  name: "ButtonText",
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  },
  props: {
    text: {
      default: ""
    },
    disabled: {
      defaulT: false,
      Type: Boolean
    },
    classNames: {
      defaul: null
    }
  }
};
</script>

<style></style>
