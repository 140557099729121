<template>
  <TransitionBounceComponent>
    <SalesTourComponent v-if="showTour && isSalesTourCompleted" />
  </TransitionBounceComponent>
</template>

<script>
import TransitionBounceComponent from "@/components/Transitions/Bounce";
import SalesTourComponent from "./Tour";

export default {
  name: "WrapperSalesTourComponent",
  data() {
    return {
      showTour: false
    };
  },
  computed: {
    isSalesTourCompleted() {
      const { user_config_general } = this.$store.getters.getUser;
      return (
        user_config_general && !user_config_general.is_sales_tour_completed
      );
    }
  },
  methods: {},
  components: {
    TransitionBounceComponent,
    SalesTourComponent
  },
  mounted() {
    setTimeout(() => {
      if (this.isSalesTourCompleted) this.showTour = true;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped></style>
