<template>
  <div class="wrapper__content-sale-document">
    <div
      class="sale-document-header d-flex justify-content-between align-items-center px-2"
    >
      <h4 class="h4 text-color-black">Retenciones</h4>
      <div class="icons">
        <ButtonCirclePlusComponent @onClick="withholdingAdd" />
        <ButtonFilterComponent
          @onChangeFilters="onChangeFilters"
          @onApplyFilters="onApplyFilters"
          :setFilters="searchFilters"
          :loading="loading"
          :setStatus="filterStatus"
          placeholder="Buscar por número documento"
          isStatus
          isSearch
          isOrderBy
          isDates
          class="mx-2"
        />
      </div>
    </div>
    <template>
      <WrapperScrollbarComponent height="90vh" @scrollingEnd="infiniteHandler">
        <WithhdingCardComponent
          v-for="(withholding, index) in filterWithholdings"
          :key="index"
          :withholding="withholding"
        />
        <infinite-loading
          @distance="90"
          @infinite="infiniteHandler"
          ref="infiniteLoading"
        >
          <div slot="spinner">
            <SkeletonOneCardComponent />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results">
            <WithholdingEmptyComponent v-if="!withholdings.length" />
          </div>
        </infinite-loading>
      </WrapperScrollbarComponent>
    </template>
  </div>
</template>

<script>
import ButtonCirclePlusComponent from "../../../components/ButtonCirclePlus";
import ButtonFilterComponent from "../../../components/ButtonFilter";
import WithhdingCardComponent from "./WithholdingCard";
import SkeletonOneCardComponent from "@/components/Animations/Loading/SkeletonOneCard";
import WrapperScrollbarComponent from "../../../../components/WrapperScrollbar";
import WithholdingEmptyComponent from "./Empty";

import withholdingService from "../services/withholdingService";

export default {
  name: "WithholdingColumnsComponent",
  data() {
    return {
      loading: true,
      searchFilters: null,
      filterStatus: [
        {
          label: "Borrador",
          key: "draft"
        },
        {
          label: "Aprobada",
          key: "success"
        },
        {
          label: "Anulada",
          key: "nuled"
        },
        {
          label: "Fallida",
          key: "failed"
        }
      ],
      page: 1,
      withholdings: [],
      breakScroll: false
    };
  },
  computed: {
    filterOrderBy() {
      const { orderBy } = this.searchFilters;
      return orderBy;
    },
    requestFilters() {
      const { search, from, to, status } = this.searchFilters;
      return { search, from, to, status };
    },
    filterWithholdings() {
      return this.withholdings.concat().sort((a, b) => {
        if (this.filterOrderBy && this.filterOrderBy === "desc") {
          return a.total > b.total ? -1 : 1;
        } else if (this.filterOrderBy && this.filterOrderBy === "asc") {
          return a.total < b.total ? -1 : 1;
        }
      });
    },
    getWithholdingFilters() {
      const { documents } = this.$store.getters.getModule("sales");
      return documents.withholdings.filters.get;
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    withholdingAdd() {
      this.goRouter("sales.withholdings.add");
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };

      this.$store.dispatch("salesDocumentsFiltersGet", {
        documentKey: "withholdings",
        filters: this.searchFilters
      });
    },
    onChangeFilters(filters) {
      this.updatedSearchFilters(filters);
    },
    onApplyFilters() {
      this.onAsyncDataForDates();
    },
    onAsyncDataForDates() {
      this.withholdings = [];
      this.page = 1;
      this.$refs.infiniteLoading.stateChanger.reset();
    },
    async getWithholdings($state) {
      try {
        let response = await withholdingService
          .getWithholdings({
            params: {
              ...{
                page: this.page
              },
              ...this.searchFilters
            }
          })
          .finally(() => {
            this.breakScroll = false;
            this.loading = false;
          });
        const { data } = response.data;
        if (data.length) {
          if (this.page === 1) {
            this.withholdings = [...this.withholdings, ...data];
          } else {
            data.forEach(item => this.withholdings.push(item));
          }
          this.page = this.page + 1;
        }

        $state.complete();
      } catch (error) {
        return false;
      }
    },
    infiniteHandler($state) {
      if (this.breakScroll) return false;
      this.breakScroll = true;
      this.getWithholdings($state);
    }
  },
  components: {
    ButtonCirclePlusComponent,
    ButtonFilterComponent,
    WithhdingCardComponent,
    SkeletonOneCardComponent,
    WrapperScrollbarComponent,
    WithholdingEmptyComponent
  },
  props: {},
  mounted() {
    this.searchFilters = this.getWithholdingFilters;
  }
};
</script>

<style lang="scss" scoped></style>
