import { render, staticRenderFns } from "./LiquidationCard.vue?vue&type=template&id=7ef088ce&scoped=true"
import script from "./LiquidationCard.vue?vue&type=script&lang=js"
export * from "./LiquidationCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef088ce",
  null
  
)

export default component.exports