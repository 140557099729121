<template>
  <img
    v-if="getUser.user_business.business_logo"
    :src="getUser.user_business.business_logo"
    class="image"
    id="image-business"
    alt="Empresa"
    :width="width"
  />
</template>

<script>
export default {
  props: {
    width: {
      default: "200p"
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  }
};
</script>

<style></style>
