<template>
  <el-drawer
    title=""
    :visible.sync="showVisibleDocumentSend"
    :with-header="false"
    custom-class="wrapper__document-send-drawer"
    @closed="onCloseDrawer"
    size="40%"
  >
    <section class="p-4">
      <h3
        class="h3 text-center font-semi-bold text-break text-color-primary mb-2"
      >
        Envia documento con <br />
        mensaje adjunto
      </h3>

      <div class="d-flex align-items-center">
        <p class="mb-0">Para:</p>
        <TagInput @onChangeTags="onTagToEmails" :values="to" />
      </div>

      <div class="d-flex align-items-center mb-4">
        <p class="mb-0">CC:</p>
        <TagInput @onChangeTags="onTagCCEmails" />
      </div>

      <EditorWysiwyg @onEditorChange="onEditorChange" :setText.sync="message" />

      <UploadFile @uploadFile="uploadFile" />

      <button
        class="btn btn-lg md__btn-primary btn-send-document"
        @click="onDocumentSend"
      >
        <img :src="icoSend" alt="Enviar documento" />
      </button>
    </section>
  </el-drawer>
</template>

<script>
import EditorWysiwyg from "./EditorWysiwyg";
import UploadFile from "./UploadFile";
import TagInput from "./TagInput";

import { icoSend, icoPlane } from "@/services/resources";
import configService from "../configurations/services/configService";

export default {
  name: "WrapperSendDocumentDrawer",

  data() {
    return {
      icoSend,
      icoPlane,
      message: "",
      to: this.toMails,
      cc: [],
      attachFile: null,
      showVisibleDocumentSend: this.visibleDocumentSend
    };
  },
  watch: {
    visibleDocumentSend(value) {
      this.showVisibleDocumentSend = value;
    },
    toMails(mails) {
      this.to = mails;
    }
  },
  methods: {
    onCloseDrawer() {
      this.$emit("onCloseDrawer");
    },
    onEditorChange({ text }) {
      this.message = text;
    },
    async handleGetUserConfigTexts() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await configService.getUserConfigTexts().finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        if (response.data.data) {
          const { message } = response.data.data;
          this.message = message;
        }
      } catch (error) {
        return false;
      }
    },
    async onDocumentSend() {
      if (!this.to.length) {
        this.$notifications.warning({
          message: "Debes añadir un destinatario"
        });
        return false;
      }

      let data = new FormData();
      data.append("message", this.message);
      data.append("cc", JSON.stringify(this.cc));
      data.append("to", JSON.stringify(this.to));
      data.append("file", this.attachFile);

      this.$emit("onDocumentSend", {
        data
      });
    },
    onTagToEmails(emails) {
      this.to = emails;
    },
    onTagCCEmails(emails) {
      this.cc = emails;
    },
    uploadFile({ files }) {
      this.attachFile = files.length ? files[0].raw : null;
    }
  },
  components: {
    TagInput,
    EditorWysiwyg,
    UploadFile
  },
  props: {
    visibleDocumentSend: {
      default: false
    },
    toMails: {
      type: Array,
      default: () => []
    },
    isLoadingConfigTexts: {
      default: true,
      tye: Boolean
    }
  },
  mounted() {
    // const { client, contact } = this.quote;
    // if (contact) this.to.push(contact.email);
    // else if (client) this.to.push(client.email);
    if (this.isLoadingConfigTexts) this.handleGetUserConfigTexts();
  }
};
</script>

<style lang="scss" scoped>
.btn-send-document {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  img {
    width: 30px;
    transform: rotate(-35deg);
  }
}

.wrapper__document-send-success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 70px;
    margin: 1rem 0;
  }
}
.el-tag {
  border-radius: 50px !important;
}
</style>
