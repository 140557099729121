<template>
  <el-popover
    placement="bottom-start"
    width="350"
    trigger="click"
    v-if="isEnabled"
  >
    <template name="—">
      <div v-loading="loading">
        <template v-if="isClients">
          <SelectBusinessComponent
            title="Empresas"
            :isAddBusiness="false"
            :setUserBusinessClientId="clientId"
            @onChangeSelect="onChangeSelectBusiness"
          />
        </template>

        <template v-if="isSearch">
          <p class="pb-2 m-0 mt-2 font-bold text-color-blank">Busqueda</p>
          <el-input
            :placeholder="placeholder"
            suffix-icon="el-icon-search"
            v-model="search"
            :clearable="true"
            @input="onChangeFilters"
            class=""
          >
          </el-input>
        </template>

        <template v-if="isDates">
          <p class="pb-2 m-0 mt-2 font-bold text-color-blank">
            Fechas
            <MoreInfoComponent content="Por defecto se listan el mes actual" />
          </p>

          <FilterBetweenDatesSelect
            :keyRangeDates="keyRangeDates"
            @onAsyncDataForDates="onAsyncDataForDates"
          />
        </template>

        <template v-if="isOrderBy">
          <p class="pb-2 m-0 mt-2 font-bold text-color-blank">Ordenar por</p>

          <el-radio-group
            v-model="orderBy"
            size="small"
            @change="onChangeFilters"
          >
            <el-radio-button label="desc">Mayor valor</el-radio-button>
            <el-radio-button label="asc">Menor valor</el-radio-button>
          </el-radio-group>
        </template>
        <template v-if="isStatus"
          ><p class="pb-2 m-0 mt-2 font-bold text-color-blank">Filtrar por</p>
          <el-radio-group v-model="status" @change="onChangeFilters">
            <el-radio
              class="d-block mb-1"
              v-for="status in setStatus"
              :label="status.key"
              :key="status.key"
              >{{ status.label }}</el-radio
            >
          </el-radio-group></template
        >

        <slot name="customs"></slot>
        <b-row>
          <b-col>
            <button
              class="m-1 float-right btn md__btn-primary"
              @click="onApplyFilters"
            >
              Buscar
            </button>
            <button
              class="m-1 float-right btn md__btn-link text-color-black"
              @click="resetFilters"
            >
              Limpiar filtros
            </button>
          </b-col>
        </b-row>
      </div>
    </template>

    <img slot="reference" :src="icoFilter" alt="filter" width="24" />
  </el-popover>
</template>

<script>
import FilterBetweenDatesSelect from "../../components/FilterBetweenDatesSelect";
import MoreInfoComponent from "../../components/MoreInfo";
import SelectBusinessComponent from "../../components/SelectBusinessV2";

import { icoFilter } from "@/services/resources";

export default {
  name: "ButtonFilterComponent",
  data() {
    return {
      icoFilter,
      search: null,
      orderBy: null,
      status: null,
      from: null,
      to: null,
      keyRangeDates: null,
      clientId: null
    };
  },
  watch: {
    loading(value) {
      this.loading = value;
    },
    setFilters(value) {
      const { search, orderBy, status, keyRangeDates, clientId } = value;
      this.search = search;
      this.orderBy = orderBy;
      this.status = status;
      this.keyRangeDates = keyRangeDates;
      this.clientId = clientId;
      this.setFilters = value;
    }
  },
  computed: {
    isEnabled() {
      return (
        this.isOrderBy ||
        this.isSearch ||
        this.isStatus ||
        this.isDates ||
        this.isClients
      );
    }
  },
  methods: {
    onChangeFilters() {
      this.$emit("onChangeFilters", {
        orderBy: this.orderBy,
        search: this.search,
        status: this.status,
        from: this.from,
        to: this.to,
        keyRangeDates: this.keyRangeDates,
        clientId: this.clientId
      });
    },
    resetFilters() {
      this.search = null;
      this.orderBy = null;
      this.status = null;
      this.from = null;
      this.to = null;
      this.keyRangeDates = null;
      this.clientId = null;
      this.onChangeFilters();
      this.$emit("onResetFilters");
    },
    onAsyncDataForDates({ searchDates }) {
      const { from, to, keyRangeDates } = searchDates;
      this.from = from;
      this.to = to;
      this.keyRangeDates = keyRangeDates;

      this.onChangeFilters();
    },
    onApplyFilters() {
      this.$emit("onApplyFilters");
    },
    onChangeSelectBusiness({ client }) {
      this.clientId = client.id ?? null;
      this.onChangeFilters();
    }
  },
  components: {
    FilterBetweenDatesSelect,
    MoreInfoComponent,
    SelectBusinessComponent
  },
  props: {
    setStatus: {
      type: Array
    },
    placeholder: {
      default: "Buscar"
    },
    isOrderBy: {
      default: false,
      type: Boolean
    },
    isSearch: {
      default: false,
      type: Boolean
    },
    isStatus: {
      default: false,
      type: Boolean
    },
    isDates: {
      default: false,
      type: Boolean
    },
    isClients: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    },
    setFilters: {
      default: null,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.item-filter:hover {
  background-color: #e4fff4;
}
.icons img {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}
</style>
