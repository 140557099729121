var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('TopBarTitleComponent',{attrs:{"title":"Ventas","showBackIcon":true}},[_c('template',{slot:"right-area"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('FilterDocumentShowComponent',{staticClass:"mr-2"}),_c('ButtonAddSaleDocumentsComponent')],1)])],2),_c('div',{staticClass:"wrapper-sale-documents"},[_c('div',{staticClass:"wrapper-sale-documents-content px-4"},[_c('div',{staticClass:"wrapper-sale-documents-main"},[_c('div',{staticClass:"wrapper__content-sale-board"},[_c('div',{staticClass:"scrollbar",attrs:{"id":"board"}},[(_vm.toggleSalesDocumentsVisibility('is_sales_quotes_visibility')
              )?_c('div',{staticClass:"wrapper-list-documents"},[_c('div',{attrs:{"id":"tour-step-0"}}),_c('QuoteColumnsComponent')],1):_vm._e(),(_vm.toggleSalesDocumentsVisibility('is_sales_invoices_visibility')
              )?_c('div',{staticClass:"wrapper-list-documents"},[_c('div',{attrs:{"id":"tour-step-1"}}),_c('InvoiceColumnsComponent')],1):_vm._e(),(_vm.toggleSalesDocumentsVisibility(
              'is_sales_credit_notes_visibility'
            )
              )?_c('div',{staticClass:"wrapper-list-documents"},[_c('div',{attrs:{"id":"tour-step-2"}}),_c('CreditNoteColumnsComponent')],1):_vm._e(),(_vm.toggleSalesDocumentsVisibility(
              'is_sales_debit_notes_visibility'
            )
              )?_c('div',{staticClass:"wrapper-list-documents"},[_c('div',{attrs:{"id":"tour-step-3"}}),_c('DebitNoteColumnsComponent')],1):_vm._e(),(_vm.toggleSalesDocumentsVisibility(
              'is_sales_liquidations_visibility'
            )
              )?_c('div',{staticClass:"wrapper-list-documents"},[_c('div',{attrs:{"id":"tour-step-1"}}),_c('LiquidationColumnsComponent')],1):_vm._e(),(_vm.toggleSalesDocumentsVisibility(
              'is_sales_withholdings_visibility'
            )
              )?_c('div',{staticClass:"wrapper-list-documents"},[_c('div',{attrs:{"id":"tour-step-4"}}),_c('WithholdingColumnsComponent')],1):_vm._e(),(_vm.toggleSalesDocumentsVisibility('is_sales_payments_visibility')
              )?_c('div',{staticClass:"wrapper-list-documents"},[_c('div',{attrs:{"id":"tour-step"}}),_c('PaymentColumnsComponent')],1):_vm._e()])])])])]),_c('WrapperSalesTourComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }