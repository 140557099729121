<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div slot="title">
      <button class="btn btn-link float-right" @click.stop="onDialogClose">
        <i class="el-icon-close"></i>
      </button>

      <h4 class="h4 font-bolder text-color-primary mb-2">
        ¡Recibe tu pago con el siguiente enlace!
      </h4>
      <span class="text-color-black"
        >Copia y comparte el siguiente enlace para recibir tus pagos</span
      >
    </div>

    <div @click.stop="copyURL">
      <el-input placeholder="Link" :value="link" readonly>
        <el-button slot="append" icon="el-icon-s-order"></el-button>
      </el-input>
    </div>

    <div
      class="mt-4 d-flex justify-content-center flex-column align-items-center"
    >
      <p class="mb-4">o</p>

      <div @click.stop="openURL">
        <button class="btn md__btn-primary-outline m-4s">
          Compartir por Whatsapp
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "WrapperPaymentLink",
  data() {
    return {
      dialogVisible: this.showDialog,
      infoPaymentLink: null,
      isLinkCopied: false
    };
  },
  computed: {
    link() {
      return this.paymentLink?.link || null;
    }
  },
  watch: {
    showDialog(v) {
      this.dialogVisible = v;
    },
    paymentLink(v) {
      this.infoPaymentLink = v;
    }
  },
  methods: {
    onDialogClose() {
      this.isLinkCopied = false;
      this.$emit("onDialogClose");
    },
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.link);

        if (!this.isLinkCopied) {
          this.$notifications.success({
            message: "Enlace de pago ¡Copiado!"
          });
        }

        this.isLinkCopied = true;
      } catch (e) {
        return false;
      }
    },
    async openURL() {
      try {
        const text = `¡Hola!, te comparto el link de pago en donde puedes realizarlo de manera rápida y segura haciendo clic en el siguiente enlace: \n${this.link}`;
        const textURI = `https://api.whatsapp.com/send?text=${encodeURI(text)}`;
        window.open(textURI, "_blank");
      } catch (e) {
        return false;
      }
    }
  },
  props: {
    paymentLink: {
      type: Object,
      default: () => ({})
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
