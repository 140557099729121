<template>
  <div class="wrapper__content-sale-document">
    <div
      class="sale-document-header d-flex justify-content-between align-items-center px-2"
    >
      <h4 class="h4 text-color-black">Notas de débito</h4>
      <div class="icons">
        <ButtonCirclePlusComponent @onClick="debitNoteAdd" />
        <ButtonFilterComponent
          @onChangeFilters="onChangeFilters"
          @onApplyFilters="onApplyFilters"
          :setFilters="searchFilters"
          :loading="loading"
          :setStatus="filterStatus"
          placeholder="Buscar por número documento"
          isStatus
          isSearch
          isOrderBy
          isDates
          class="mx-2"
        />
      </div>
    </div>
    <template>
      <WrapperScrollbarComponent height="90vh" @scrollingEnd="infiniteHandler">
        <DebitNoteCardComponent
          v-for="(debitNote, index) in filterDebitNotes"
          :key="index"
          :debitNote="debitNote"
        />
        <infinite-loading
          @distance="90"
          @infinite="infiniteHandler"
          ref="infiniteLoading"
        >
          <div slot="spinner">
            <SkeletonOneCardComponent />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results">
            <DebitNoteEmptyComponent v-if="!debitNotes.length" />
          </div>
        </infinite-loading>
      </WrapperScrollbarComponent>
    </template>
  </div>
</template>

<script>
import ButtonCirclePlusComponent from "../../../components/ButtonCirclePlus";
import ButtonFilterComponent from "../../../components/ButtonFilter";
import DebitNoteCardComponent from "./DebitNoteCard";
import SkeletonOneCardComponent from "@/components/Animations/Loading/SkeletonOneCard";
import WrapperScrollbarComponent from "../../../../components/WrapperScrollbar";
import DebitNoteEmptyComponent from "./Empty";

import debitNoteService from "../services/debitNoteService";

export default {
  name: "CreditNoteColumnsComponent",
  data() {
    return {
      loading: true,
      searchFilters: null,
      filterStatus: [
        {
          label: "Borrador",
          key: "draft"
        },
        {
          label: "Aprobada",
          key: "success"
        },
        {
          label: "Anulada",
          key: "nuled"
        },
        {
          label: "Fallida",
          key: "failed"
        }
      ],
      page: 1,
      debitNotes: [],
      breakScroll: false
    };
  },
  computed: {
    filterOrderBy() {
      const { orderBy } = this.searchFilters;
      return orderBy;
    },
    requestFilters() {
      const { search, from, to, status } = this.searchFilters;
      return { search, from, to, status };
    },
    filterDebitNotes() {
      return this.debitNotes.concat().sort((a, b) => {
        if (this.filterOrderBy && this.filterOrderBy === "desc") {
          return a.total > b.total ? -1 : 1;
        } else if (this.filterOrderBy && this.filterOrderBy === "asc") {
          return a.total < b.total ? -1 : 1;
        }
      });
    },
    getDebitNotesFilters() {
      const { documents } = this.$store.getters.getModule("sales");
      return documents.debitNotes.filters.get;
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    debitNoteAdd() {
      this.goRouter("sales.debitNote.add");
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };

      this.$store.dispatch("salesDocumentsFiltersGet", {
        documentKey: "debitNotes",
        filters: this.searchFilters
      });
    },
    onChangeFilters(filters) {
      this.updatedSearchFilters(filters);
    },
    onApplyFilters() {
      this.onAsyncDataForDates();
    },
    onAsyncDataForDates() {
      this.debitNotes = [];
      this.page = 1;
      this.$refs.infiniteLoading.stateChanger.reset();
    },
    async getDebitNotes($state) {
      try {
        let response = await debitNoteService
          .getDebitNotes({
            params: {
              ...{
                page: this.page
              },
              ...this.requestFilters
            }
          })
          .finally(() => {
            this.breakScroll = false;
            this.loading = false;
          });
        const { data } = response.data;
        if (data.length) {
          if (this.page === 1) {
            this.debitNotes = [...this.debitNotes, ...data];
          } else {
            data.forEach(item => this.debitNotes.push(item));
          }
          this.page = this.page + 1;
        }

        $state.complete();
      } catch (error) {
        return false;
      }
    },
    infiniteHandler($state) {
      if (this.breakScroll) return false;
      this.breakScroll = true;
      this.getDebitNotes($state);
    }
  },
  components: {
    ButtonCirclePlusComponent,
    ButtonFilterComponent,
    DebitNoteCardComponent,
    SkeletonOneCardComponent,
    WrapperScrollbarComponent,
    DebitNoteEmptyComponent
  },
  props: {},
  mounted() {
    this.searchFilters = this.getDebitNotesFilters;
  }
};
</script>

<style lang="scss" scoped></style>
