<template>
  <div
    class="avatar bg-color-green d-flex justify-content-center align-items-center"
  >
    <h4 class="h4 m-0 font-bold text-uppercase">{{ formatText }}</h4>
  </div>
</template>

<script>
export default {
  name: "TextAvatarComponent",
  computed: {
    formatText() {
      return this.text.slice(0, 2);
    }
  },
  props: {
    text: {
      default: "Mydessk"
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>
