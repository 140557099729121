<template>
  <el-popover
    placement="top-start"
    title=""
    width="200"
    trigger="hover"
    :content="content"
  >
    <img :src="icoCircleInfo" alt="info" :width="width" slot="reference" />
  </el-popover>
</template>

<script>
import { icoCircleInfo } from "@/services/resources";

export default {
  name: "MoreInfoComponent",
  data() {
    return {
      icoCircleInfo
    };
  },
  props: {
    width: {
      default: "18"
    },
    content: {
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
