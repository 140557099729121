import Api, { configFormData } from "@/config/Api";

export default {
  getLiquidations(options = {}) {
    return Api().get(`/liquidations`, options);
  },

  getClientLiquidation(clientId, options = {}) {
    return Api().get(`/liquidations?client=${clientId}`, options);
  },

  addLiquidation(data) {
    return Api().post(`/liquidations`, data);
  },

  getLiquidationById(liquidationId) {
    return Api().get(`/liquidations/${liquidationId}`);
  },

  updateLiquidationId(liquidationId, data) {
    return Api().put(`/liquidations/${liquidationId}`, data);
  },

  deleteLiquidationById(liquidationId) {
    return Api().delete(`/liquidations/${liquidationId}`);
  },

  sendLiquidationEmail({ data }) {
    return Api().post(`/liquidations/send-email`, data, configFormData);
  },

  sendLiquidationSRI(data) {
    return Api().post(`/sri/signature/liquidation`, data);
  },

  liquidationDownloadPdf({ id }) {
    return Api().get(`/u/download/liquidation?id=${id}`, {
      responseType: "arraybuffer"
    });
  },
  updateLiquidationStatus(data) {
    return Api().post(`/status/liquidations`, data);
  }
};
