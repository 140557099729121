<template>
  <img :src="getLogo" alt="Logo Mydessk" :width="width" @click="onClick" class="cursor-point" />
</template>

<script>
import { myDesskBlack, myDesskWhite, myDesskReduce } from "@/services/resources";

export default {
  name: "LogoComponent",
  data: () => ({
    myDesskBlack,
    myDesskWhite,
    myDesskReduce
  }),
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  },
  computed: {
    getLogo() {
      if (this.color === "black") {
        return myDesskBlack;
      }

      if (this.color === "blue") {
        return myDesskReduce;
      }

      return myDesskWhite;
    }
  },
  props: {
    width: {
      default: "160"
    },
    color: {
      default: "black"
    }
  }
};
</script>

<style lang="scss" scoped></style>
