<template>
  <div class="wrapper__content-sale-document">
    <div class="sale-document-header d-flex justify-content-between align-items-center px-2">
      <h4 class="h4 text-color-black">Facturas</h4>
      <div class="icons">
        <DownloadXML />
        <ButtonFilterComponent @onChangeFilters="onChangeFilters" @onApplyFilters="onApplyFilters"
          @onResetFilters="onResetFilters" :setFilters="searchFilters" :loading="loading" :setStatus="filterStatus"
          placeholder="Buscar por número documento" isStatus isSearch isOrderBy isDates isClients class="mx-2">
          <template slot="customs">
            <p class="pb-2 m-0 mt-2 font-bold text-color-blank">Buscar por</p>

            <el-radio-group v-model="filterIsPaid" @change="onChangeFilterLocal">
              <el-radio class="d-block mb-1" label="1">Pagada</el-radio>
              <el-radio class="d-block mb-1" label="0">No pagada</el-radio>
            </el-radio-group>
          </template>
        </ButtonFilterComponent>
        <ButtonCirclePlusComponent @onClick="invoiceAdd" />
      </div>
    </div>
    <template>
      <WrapperScrollbarComponent height="90vh" @scrollingEnd="infiniteHandler">
        <InvoiceCardComponent v-for="(invoice, index) in filterInvoices" :key="index" :invoice="invoice" />
        <infinite-loading @distance="90" @infinite="infiniteHandler" ref="infiniteLoading">
          <div slot="spinner">
            <SkeletonOneCardComponent />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results">
            <InvoiceEmptyComponent v-if="!invoices.length" />
          </div>
        </infinite-loading>
      </WrapperScrollbarComponent>
    </template>
  </div>
</template>

<script>
import ButtonCirclePlusComponent from "../../../components/ButtonCirclePlus";
import ButtonFilterComponent from "../../../components/ButtonFilter";
import InvoiceEmptyComponent from "./Empty";
import InvoiceCardComponent from "./InvoiceCard";
import SkeletonOneCardComponent from "@/components/Animations/Loading/SkeletonOneCard";
import WrapperScrollbarComponent from "../../../../components/WrapperScrollbar";
import DownloadXML from "../components/DownloadXML"

import invoiceService from "../services/invoiceService";


export default {
  name: "InvoiceColumnsComponent",
  data() {
    return {
      loading: true,
      searchFilters: null,
      filterIsPaid: null,
      filterStatus: [
        {
          label: "Borrador",
          key: "draft"
        },
        {
          label: "Aprobada",
          key: "success"
        },
        {
          label: "Anulada",
          key: "nuled"
        },
        {
          label: "Fallida",
          key: "failed"
        }
      ],
      page: 1,
      invoices: [],
      breakScroll: false,
      debounceTimer: null,
      customRangedates: null
    };
  },
  watch: {
    notication: {
      handler() {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.onAsyncDataForDates();
        }, 200);
      },
      immediate: true
    }
  },
  computed: {
    notication() {
      return this.$store.getters.getNotification;
    },
    filterOrderBy() {
      const { orderBy } = this.searchFilters;
      return orderBy;
    },
    requestFilters() {
      const { search, from, to, status, clientId: client } = this.searchFilters;
      return { search, from, to, status, client };
    },
    filterInvoices() {
      return this.invoices.concat().sort((a, b) => {
        if (this.filterOrderBy && this.filterOrderBy === "desc") {
          return a.total > b.total ? -1 : 1;
        } else if (this.filterOrderBy && this.filterOrderBy === "asc") {
          return a.total < b.total ? -1 : 1;
        }
      });
    },
    getInvoicesFilters() {
      const { documents } = this.$store.getters.getModule("sales");
      return documents.invoices.filters.get;
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    invoiceAdd() {
      this.goRouter("sales.invoices.add");
    },
    onChangeFilterLocal() {
      this.updatedSearchFilters({
        is_paid: this.filterIsPaid
      });
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };

      this.$store.dispatch("salesDocumentsFiltersGet", {
        documentKey: "invoices",
        filters: this.searchFilters
      });
    },
    onChangeFilters(filters) {
      this.updatedSearchFilters(filters);
    },
    onApplyFilters() {
      this.onAsyncDataForDates();
    },
    onResetFilters() {
      this.filterIsPaid = null;

      this.onChangeFilterLocal();
    },
    onAsyncDataForDates() {
      this.invoices = [];
      this.page = 1;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.$store.dispatch("sendNotification", false);
    },
    
    async getInvoices($state) {
      try {
        this.loading = true;
        let response = await invoiceService
          .getInvoices({
            params: {
              ...{
                page: this.page,
                client: this.getClientId,
                is_paid: this.filterIsPaid
              },
              ...this.requestFilters
            }
          })
          .finally(() => {
            this.breakScroll = false;
            this.loading = false;
          });
        const { data } = response.data;
        if (data.length) {
          if (this.page === 1) {
            this.invoices = [...this.invoices, ...data];
          } else {
            data.forEach(item => this.invoices.push(item));
          }
          this.page = this.page + 1;
        }

        $state.complete();
      } catch (error) {
        return false;
      }
    },
    infiniteHandler($state) {
      if (this.breakScroll) return false;
      this.breakScroll = true;
      this.getInvoices($state);
    },
    setInitFilters() {
      // All filters
      this.searchFilters = this.getInvoicesFilters;

      // Local filters
      this.filterIsPaid = this.getInvoicesFilters?.is_paid;
    }
  },
  components: {
    ButtonCirclePlusComponent,
    ButtonFilterComponent,
    InvoiceEmptyComponent,
    InvoiceCardComponent,
    SkeletonOneCardComponent,
    WrapperScrollbarComponent,
    DownloadXML
  },
  props: {
    getClientId: {
      default: null
    }
  },
  mounted() {
    this.setInitFilters();
  }
};
</script>

<style lang="scss" scoped></style>

