<template>
  <el-upload
    class="mt-4"
    action="#"
    :on-remove="handleRemove"
    :on-change="handleChange"
    :limit="1"
    :on-exceed="handleExceed"
    :file-list="fileList"
    :auto-upload="false"
  >
    <i class="el-icon-paperclip"></i> Añadir adjunto
    <div slot="tip" class="el-upload__tip"></div>
  </el-upload>
</template>

<script>
export default {
  name: "UploadFileComponent",
  data() {
    return {
      fileList: []
    };
  },
  methods: {
    handleRemove(file, fileList) {
      if (file || fileList.length) this.handleFileEmit(fileList);
    },
    handleChange(file, fileList) {
      if (file || fileList.length) this.handleFileEmit(fileList);
    },
    handleExceed() {
      this.$notifications.warning({
        message: `El límite permitido de adjunto es 1`
      });
    },
    handleFileEmit(fileList) {
      this.$emit("uploadFile", { files: fileList });
    }
  }
};
</script>

<style></style>
