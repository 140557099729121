<template>
  <div class="wrapper-print-invoice" v-if="invoice">
    <div class="d-flex justify-content-center mb-4">
      <business-logo />
    </div>

    <p class="font-semi-bold mt-2">
      Nombre: {{ getUser.user_business.social_reason }}
    </p>
    <p class="font-semi-bold">RUC: {{ getUser.user_business.business_ruc }}</p>
    <p class="font-semi-bold">
      Matriz: {{ getUser.user_business.business_address }}
    </p>
    <p class="font-semi-bold" v-if="branch">
      Sucursal: {{ branch }}
    </p>
    <p class="font-semi-bold">
      Teléfono: {{ getUser.user_business.business_phone }}
    </p>

    <h4 class="font-semi-bold h4 text-center my-4">
      DETALLE FACTURA ELECTRONICA
    </h4>

    <h5 class="h5 font-bold m-0">N° {{ invoice.number_invoice }}</h5>
    <p class="font-bold m-0 text-pre-wrap access_key">
      Clave acceso {{ invoice.access_key }}
    </p>
    <h5 class="h5 m-0 mt-2">{{ invoice.client.name }}</h5>
    <p class="font-semi-bold m-0">{{ invoice.client.document }}</p>
    <p class="font-semi-bold m-0">
      Emitida: {{ invoice.emission_date_for_view }}
    </p>

    <p class="font-semi-bold m-0">
      Forma de pago <br />
      {{ invoice.sri_forms_payment.title }}
    </p>

    <table class=" mt-4">
      <tr>
        <th width="15%">CANT</th>
        <th width="55%">DETALLE</th>
        <th width="15%">P.U</th>
        <th width="15%">P.T</th>
      </tr>
      <tbody>
        <tr v-for="(product, index) in invoice.products" :key="index">
          <td>{{ product.product_quantity }}</td>
          <td>{{ product.product.name }}</td>
          <td>
            <FormatAmount :amount="product.product_amount" />
          </td>
          <td>
            <FormatAmount :amount="product.product_amount * product.product_quantity" />
          </td>
        </tr>
      </tbody>
    </table>

    <hr />
    <DocumentCalculator readOnly :setProducts="invoice.products" :setDisc="invoice.discount"
      :setServ="invoice.service" />
    <hr />
  </div>
</template>

<script>
import BusinessLogo from "@/components/Business/BusinessLogo";
import DocumentCalculator from "@/modules/dashboard/sales/components/DocumentCalculator";
import FormatAmount from "@/components/General/FormatAmount";

export default {
  name: "PrintInvoice",
  props: {
    invoice: {
      default: () => null,
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    branch(){
      const { address, is_branch } = this.invoice.emission_point.user_business_establishment;
      return is_branch ? address : null;
    }
  },
  components: {
    BusinessLogo,
    DocumentCalculator,
    FormatAmount
  },

};
</script>

<style lang="scss" scoped>
.wrapper-print-invoice {
  max-width: 12cm;
  padding: 1cm;
  border: solid 1px #e3e3e3;
  p {
    margin: 0;
  }

  .access_key {
    font-size: 12px !important;
  }

  table {
    font-size: 12px !important;
  }
}
</style>
