<template>
  <section class="">
    <TopBarTitleComponent title="Ventas" :showBackIcon="true">
      <template slot="right-area">
        <div class=" d-flex justify-content-end align-items-center">
          <FilterDocumentShowComponent class="mr-2" />
          <ButtonAddSaleDocumentsComponent />
        </div>
      </template>
    </TopBarTitleComponent>

    <div class="wrapper-sale-documents">
      <div class="wrapper-sale-documents-content px-4">
        <div class="wrapper-sale-documents-main">
          <div class="wrapper__content-sale-board">
            <div id="board" class="scrollbar">
              <div class="wrapper-list-documents" v-if="toggleSalesDocumentsVisibility('is_sales_quotes_visibility')
                ">
                <div id="tour-step-0"></div>
                <QuoteColumnsComponent />
              </div>
              <div class="wrapper-list-documents" v-if="toggleSalesDocumentsVisibility('is_sales_invoices_visibility')
                ">
                <div id="tour-step-1"></div>
                <InvoiceColumnsComponent />
              </div>
              <div class="wrapper-list-documents" v-if="toggleSalesDocumentsVisibility(
                'is_sales_credit_notes_visibility'
              )
                ">
                <div id="tour-step-2"></div>
                <CreditNoteColumnsComponent />
              </div>
              <div class="wrapper-list-documents" v-if="toggleSalesDocumentsVisibility(
                'is_sales_debit_notes_visibility'
              )
                ">
                <div id="tour-step-3"></div>
                <DebitNoteColumnsComponent />
              </div>
              <div class="wrapper-list-documents" v-if="toggleSalesDocumentsVisibility(
                'is_sales_liquidations_visibility'
              )
                ">
                <div id="tour-step-1"></div>
                <LiquidationColumnsComponent />
              </div>
              <div class="wrapper-list-documents" v-if="toggleSalesDocumentsVisibility(
                'is_sales_withholdings_visibility'
              )
                ">
                <div id="tour-step-4"></div>
                <WithholdingColumnsComponent />
              </div>
              <div class="wrapper-list-documents" v-if="toggleSalesDocumentsVisibility('is_sales_payments_visibility')
                ">
                <div id="tour-step"></div>
                <PaymentColumnsComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <WrapperSalesTourComponent />
  </section>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import QuoteColumnsComponent from "./quotes/components/QuoteColumns";
import InvoiceColumnsComponent from "./invoices/components/InvoiceColumns";
import CreditNoteColumnsComponent from "./credit-note/components/CreditNoteColumns";
import DebitNoteColumnsComponent from "./debit-note/components/DebitNoteColumns";
import WithholdingColumnsComponent from "./withholdings/components/WithholdingColumns";
import PaymentColumnsComponent from "./payments/components/PaymentColumns";
import ButtonAddSaleDocumentsComponent from "../components/ButtonAddDocuments";
import WrapperSalesTourComponent from "../components/WrapperTour";
import FilterDocumentShowComponent from "../components/FilterDocumentShow";
import LiquidationColumnsComponent from "./liquidation/components/LiquidationColumns";
export default {
  name: "SalesDocumentsPage",
  data() {
    return {
      clientId: null
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    toggleSalesDocumentsVisibility(key) {
      const { user_config_general } = this.getUser;
      if (user_config_general && user_config_general.hasOwnProperty(key))
        return user_config_general[key];
      else return true;
    }
  },
  components: {
    TopBarTitleComponent,
    ButtonAddSaleDocumentsComponent,
    QuoteColumnsComponent,
    WrapperSalesTourComponent,
    InvoiceColumnsComponent,
    PaymentColumnsComponent,
    CreditNoteColumnsComponent,
    DebitNoteColumnsComponent,
    WithholdingColumnsComponent,
    FilterDocumentShowComponent,
    LiquidationColumnsComponent
  },
  mounted() { }
};
</script>

<style lang="scss" scoped></style>
