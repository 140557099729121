<template>
  <el-upload
    class="upload-demo"
    action="#"
    drag
    :auto-upload="autoUpload"
    :show-file-list="showFileList"
    :multiple="multiple"
    :limit="limit"
    ref="upload"
    :accept="accept"
    :on-remove="handleRemove"
    :on-change="handleChange"
    :on-exceed="handleExceed"
  >
    <div>
      <i class="el-icon-upload2 el-icon-upload"></i>
      <div class="el-upload__text">
        <h6>
          Carga tus documentos en formato <br />
          {{ accept }}<br />
        </h6>
        <h6><strong>o</strong></h6>
        <p>
          <a href="#" class="a-link text-color-secondary">
            <strong>Seleccionar del ordenador</strong>
          </a>
        </p>
      </div>
      <div slot="tip" class="el-upload__tip"></div>
    </div>
  </el-upload>
</template>

<script>
export default {
  name: "PaymentDocUp",
  methods: {
    handleRemove(file, fileList) {
      if (file || fileList.length)
        this.$emit("onRemove", { file, filesList: fileList });
    },
    handleChange(file, fileList) {
      if (file || fileList.length)
        this.$emit("onAdd", { file, filesList: fileList });
    },
    handleExceed() {
      this.$notifications.warning({
        message: `El límite permitido de adjunto es ${this.limit}`
      });
    }
  },
  props: {
    limit: {
      default: 1
    },
    accept: {
      default: ".png, .jpg, .pdf"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    autoUpload: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
