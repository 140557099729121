<template>
  
    <img :src="icoWooCommerce" alt="" v-if="urlImage" />
    
 
</template>

<script>
import { icoWooCommerce } from "@/services/resources";
export default {
  name: "ImageIconWoocommerce",
  data() {
    return {
      icoWooCommerce
    };
  },
  props: {
    urlImage: {
      default: null
    }
  }

};

</script>