<template>
  <div class="icons">
    <div id="divDownloadXML"></div>
    <el-popover placement="bottom-start" width="350" trigger="click">
      <template>
        <h6 class="title text-color-black">Descarga en Lote</h6>
        <el-date-picker v-model="customRangedates" format="dd-MM-yyyy" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>

        <el-button @click="downloadXML" class="m-1 float-right btn md__btn-primary ">Descargar</el-button>
        <button class="m-1 float-right btn md__btn-link text-color-black" @click="resetValues">
          Limpiar
        </button>
      </template>
      <img slot="reference" :src="icoDownloadDoc" alt="download" width="22" class="mx-1" />
    </el-popover>
  </div>
</template>

<script>
import invoiceService from "../services/invoiceService";
import { icoDownloadDoc } from "@/services/resources";

export default {
  data() {
    return {
      customRangedates: null,
      icoDownloadDoc
    }
  },

  props: {
    clienteId: null
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    async downloadXML() {

      if (!this.customRangedates) {
        return;
      }

      let clienteId = this.getUser.user_business.id;
      let dateRange = this.customRangedates;

      try {
        this.loading = true;

        let response = await invoiceService
          .invoiceDownloadXML(clienteId, {
            date_from: dateRange[0],
            date_to: dateRange[1]
          })
        //
        const data = response.data;
        //
        const divDowloadXML = document.getElementById("divDownloadXML");
        const fileName = 'DownloadLoteXMLs.zip';
        const urlDown = window.URL.createObjectURL(data);
        //
        const anchor = document.createElement('a');
        //
        anchor.setAttribute("download", fileName);
        anchor.setAttribute("href", urlDown);
        anchor.innerHTML = fileName;
        divDowloadXML.appendChild(anchor)

        anchor.click()
        window.URL.revokeObjectURL(urlDown);
        divDowloadXML.removeChild(anchor);

      } catch (error) {
        return false
      }
    },
    resetValues() {
      this.customRangedates = null
    } 
  },
}
</script>
<style lang="scss" scoped>
.el-range-editor {
  width: 100% !important;
}
.title {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}
.icons img {
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.13);
  }
}
</style>