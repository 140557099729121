<template>
  <div class="wrapper__content-sale-document">
    <div
      class="sale-document-header d-flex justify-content-between align-items-center px-2"
    >
      <h4 class="h4 text-color-black">Links cobros</h4>
      <div class="icons">
        <ButtonCirclePlusComponent @onClick="togglePaymentAdd" />
        <ButtonFilterComponent
          @onChangeFilters="onChangeFilters"
          @onApplyFilters="onApplyFilters"
          :setFilters="searchFilters"
          :loading="loading"
          placeholder="Buscar por número documento"
          isSearch
          isOrderBy
          isDates
          class="mx-2"
        />
      </div>
    </div>
    <template>
      <WrapperScrollbarComponent height="90vh" @scrollingEnd="infiniteHandler">
        <PaymentCardComponent
          v-for="(payment, index) in filterPayments"
          :key="index"
          :payment="payment"
        />
        <infinite-loading
          @distance="90"
          @infinite="infiniteHandler"
          ref="infiniteLoading"
        >
          <div slot="spinner">
            <SkeletonOneCardComponent />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results">
            <PaymentEmptyComponent
              v-if="!payments.length"
              @onPaymentAdd="togglePaymentAdd"
            />
          </div>
        </infinite-loading>
      </WrapperScrollbarComponent>
    </template>
    <PaymentAddComponent
      :visible.sync="isPaymentAdd"
      @onClose="togglePaymentAdd"
    />
  </div>
</template>

<script>
import ButtonCirclePlusComponent from "../../../components/ButtonCirclePlus";
import ButtonFilterComponent from "../../../components/ButtonFilter";
import PaymentCardComponent from "./PaymentCard";
import PaymentEmptyComponent from "./Empty";
import PaymentAddComponent from "./PaymentAdd";
import SkeletonOneCardComponent from "@/components/Animations/Loading/SkeletonOneCard";
import WrapperScrollbarComponent from "../../../../components/WrapperScrollbar";

import paymentService from "../services/paymentService";

export default {
  name: "PaymentColumnsComponent",
  data() {
    return {
      isPaymentAdd: false,
      loading: true,
      searchFilters: null,
      page: 1,
      payments: [],
      breakScroll: false
    };
  },
  computed: {
    filterOrderBy() {
      const { orderBy } = this.searchFilters;
      return orderBy;
    },
    requestFilters() {
      const { search, from, to } = this.searchFilters;
      return { search, from, to };
    },
    filterPayments() {
      return this.payments.concat().sort((a, b) => {
        if (this.filterOrderBy && this.filterOrderBy === "desc") {
          return a.total > b.total ? -1 : 1;
        } else if (this.filterOrderBy && this.filterOrderBy === "asc") {
          return a.total < b.total ? -1 : 1;
        }
      });
    },
    getPaymentsFilters() {
      const { documents } = this.$store.getters.getModule("sales");
      return documents.payments.filters.get;
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    togglePaymentAdd() {
      this.goRouter("sales.invoices.add");
      // this.isPaymentAdd = !this.isPaymentAdd;
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };

      this.$store.dispatch("salesDocumentsFiltersGet", {
        documentKey: "payments",
        filters: this.searchFilters
      });
    },
    onChangeFilters(filters) {
      this.updatedSearchFilters(filters);
    },
    onApplyFilters() {
      this.onAsyncDataForDates();
    },
    onAsyncDataForDates() {
      this.payments = [];
      this.page = 1;
      this.$refs.infiniteLoading.stateChanger.reset();
    },
    async getPayments($state) {
      try {
        this.loading = true;
        let response = await paymentService
          .getPayments({
            params: {
              ...{
                page: this.page
              },
              ...this.requestFilters
            }
          })
          .finally(() => {
            this.breakScroll = false;
            this.loading = false;
          });
        const { data } = response.data;
        if (data.length) {
          if (this.page === 1) {
            this.payments = [...this.payments, ...data];
          } else {
            data.forEach(item => this.payments.push(item));
          }
          this.page = this.page + 1;
        }

        $state.complete();
      } catch (error) {
        return false;
      }
    },
    infiniteHandler($state) {
      if (this.breakScroll) return false;
      this.breakScroll = true;
      this.getPayments($state);
    }
  },
  components: {
    ButtonCirclePlusComponent,
    ButtonFilterComponent,
    PaymentCardComponent,
    PaymentEmptyComponent,
    PaymentAddComponent,
    SkeletonOneCardComponent,
    WrapperScrollbarComponent
  },
  mounted() {
    this.searchFilters = this.getPaymentsFilters;
  }
};
</script>

<style></style>
