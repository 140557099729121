<template>
  <vue-editor
    v-model="text"
    :editor-toolbar="customToolbar"
    @text-change="onEditorChange"
  ></vue-editor>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "EditorWysiwyg",
  data() {
    return {
      text: this.setText,
      customToolbar: [
        ["bold", "italic", "underline", "strike", "blockquote"],
        // [
        //   { align: "" },
        //   { align: "center" },
        //   { align: "justify" },
        //   { align: "right" }
        // ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        // [{ indent: "-1" }, { indent: "+1" }]
        ["link"]
      ]
    };
  },
  methods: {
    onEditorChange() {
      this.$emit("onEditorChange", { text: this.text });
    }
  },
  watch: {
    setText(newText) {
      this.text = newText;
    }
  },
  props: {
    setText: {
      default: ""
    }
  },
  components: {
    VueEditor
  }
};
</script>

<style lang="scss" scoped></style>
