<template>
  <div
    class="wrapper__card-empty-documents d-flex flex-column justify-content-center align-items-center bg-color-write border-radius p-4 m-4"
  >
    <div class="wrapper__card-empty-image" :style="stylesImage">
      <img :src="imageDeskConnect" alt="" width="74" />
    </div>
    <div class="wrapper__card-empty-info my-2">
      <p class="text-center">
        No se encontró <br />
        ningúna retención
      </p>
    </div>
    <div class="wrapper__card-empty-action">
      <ButtonPrimaryComponent
        title="¡Crea una ahora!"
        @onSaveClick="withholdingAdd('sales.withholdings.add')"
      />
    </div>
  </div>
</template>

<script>
import { imageDeskConnect, bgOval } from "@/services/resources";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";

export default {
  name: "WithholdingEmptyComponent",
  data() {
    return {
      imageDeskConnect,
      bgOval,
      stylesImage: {
        backgroundImage: `url(${bgOval})`
      }
    };
  },
  methods: {
    withholdingAdd(name) {
      this.$router.push({ name });
    }
  },
  components: {
    ButtonPrimaryComponent
  }
};
</script>

<style lang="scss" scoped></style>
