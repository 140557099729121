<template>
  <span class="text-color-secondary font-semi-bold p-1" v-if="success">
    <img :src="icoCircleChecked" alt="paid" class="mr-1" />{{ text }}</span
  >
</template>

<script>
import { icoCircleChecked } from "@/services/resources";

export default {
  name: "CheckSuccessfully",
  data() {
    return {
      icoCircleChecked
    };
  },
  props: {
    success: {
      default: false,
      type: Boolean
    },
    text: {
      default: "",
      type: String
    }
  }
};
</script>

<style lang="scss" scoped></style>
