import Api, { configFormData } from "@/config/Api";

export default {
  getWithholdings(options = {}) {
    return Api().get(`/withholdings`, options);
  },
  getWithholdingId(withholdingId) {
    return Api().get(`/withholdings/${withholdingId}`);
  },
  addWithholdings(data) {
    return Api().post("/withholdings", data);
  },
  updateWithholdingId(withholdingId, data) {
    return Api().put(`/withholdings/${withholdingId}`, data);
  },
  deleteWithholdingId(withholdingId) {
    return Api().delete(`/withholdings/${withholdingId}`);
  },
  sendWithholdingSRI(data) {
    return Api().post(`/sri/signature/withholding`, data);
  },
  sendWithholdingEmail({ data }) {
    return Api().post(`/withholdings/send-email`, data, configFormData);
  },
  updateWithholdingStatus(data) {
    return Api().post(`/status/withholdings`, data);
  }
};
