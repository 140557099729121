<template>
  <div>
    <div
      v-if="invoice"
      class="sale-document-body md__border border-radius bg-color-write p-3 mb-2"
    >
      <div class="sale-document-info">
        <div class="sale-document-description d-flex justify-content-between">
          <TextAvatarComponent :text="invoice.business_name" class="m-2" />
          <div class="info cursor-point" @click.stop="invoiceView">
            <p class="m-0 text-color-primary str-limit-space">
              N° {{ invoice.number_invoice }}
            </p>
            <p class="m-0 font-bold text-color-primary str-limit-space">
              {{ invoice.client.name }}
            </p>
            <p class="m-0">
              <small>
                <span class="font-semi-bold mr-2" v-if="quote"
                  >Cotización: #{{ quote.code }}-V{{
                    quoteVersion.version_number
                  }}</span
                ><span class="">creado: {{ invoice.created_at }}</span>
              </small>
            </p>
          </div>
          <div class="sale-document-number">
            <h5 class="h5 font-bold">
              <FormatAmount :amount="invoice.total" />
            </h5>
          </div>
        </div>
      </div>

      <div
        class="sale-document-actions d-flex justify-content-between align-items-center mt-4"
      >
        <div class="status d-flex">
          <StatusReadDocumentsComponent
            :status="invoice.status"
            typeStatus="short"
          />
          <CheckSuccessfully
            class="ml-2"
            :success="invoice.is_paid"
            text="Pagada"
          />
          <div class="ml-2 mt-1">
            <ImageIconWoocommerce :urlImage="invoice.woocomerce_id" />
            <small
              ><span class="font-semi-bold mr-2">
                {{ invoice.woocomerce_id }}</span
              ></small
            >
          </div>
          <!-- <InvoiceDropdowsStatusComponent class="mr-2" /> -->
          <!-- <TagExpDateComponent /> -->
          <!-- <p class="ml-4 font-bold text-color-primary">V : 1</p> -->
          <div class="next">
            <!-- <ButtonCircleNextComponent /> -->
            <InvoiceOptionsByStatus
              :status="invoice.status"
              :id="invoice.id"
              :is_paid="invoice.is_paid"
              @goPage="goRouter"
              @payLink="getInvoicePaymentLink"
              @onUpdate="onUpdate"
            />
          </div>
        </div>
      </div>
      <WrapperPaymentLink
        :paymentLink="paymentLink"
        :showDialog.sync="showDialogPayLink"
        @onDialogClose="onDialogPayLinkClose"
      />
    </div>
    <PaymentUploadDocument
      :visible.sync="showEnablePaylink"
      @onClose="onClosePaymentUpDoc"
      v-if="showEnablePaylink"
    />
  </div>
</template>

<script>
import TextAvatarComponent from "../../../components/TextAvatar";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import CheckSuccessfully from "@/components/General/CheckSuccessfully";
import WrapperPaymentLink from "../../../components/WrapperPaymentLink";
import FormatAmount from "@/components/General/FormatAmount";
import PaymentUploadDocument from "../../payments/components/PaymentUploadDocument";
import ImageIconWoocommerce from "../../../../components/ImageIconWoocommerce";
import paymentService from "../../payments/services/paymentService";
import InvoiceOptionsByStatus from "./InvoiceOptionsByStatus.vue";

export default {
  name: "InvoiceCardComponent",
  data() {
    return {
      showDialogPayLink: false,
      paymentLink: null,
      //  icoWooCommerce,
      showEnablePaylink: false
    };
  },
  computed: {
    quote() {
      const { quote } = this.invoice;
      return quote;
    },
    quoteVersion() {
      const { quote_version } = this.invoice;
      return quote_version;
    },
    isStatusNuled() {
      return this.invoice.status === "nuled";
    },
    isStatusApproved() {
      return this.invoice.status === "success";
    },
    isPaidLink() {
      return this.invoice.isPaidLink;
    },
    isShowPayLink() {
      return (
        !this.isStatusNuled &&
        !this.invoice?.is_paid &&
        !this.isPaidLink &&
        this.isStatusApproved
      );
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    invoiceView() {
      this.goRouter("sales.invoices.view", {
        id: this.invoice.id
      });
    },
    onDialogPayLinkClose() {
      this.showDialogPayLink = false;
    },
    onClosePaymentUpDoc() {
      this.showEnablePaylink = false;
    },
    onUpdate() {
      this.$emit("onUpdate");
    },
    async getInvoicePaymentLink() {
      try {
        this.$store.dispatch("toggleRequestLoading");

        let response = await paymentService
          .getInvoicePaymentLink({
            invoice_id: this.invoice.id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data, upload_docs = false } = response.data;

        if (success) {
          this.showDialogPayLink = true;
          this.paymentLink = data;
        } else if (upload_docs) {
          this.showEnablePaylink = true;
        }
      } catch (error) {
        return false;
      }
      return false;
    }
  },
  components: {
    TextAvatarComponent,
    StatusReadDocumentsComponent,
    CheckSuccessfully,
    WrapperPaymentLink,
    FormatAmount,
    PaymentUploadDocument,
    ImageIconWoocommerce,
    InvoiceOptionsByStatus
  },
  props: {
    invoice: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
