<template>
  <button class="btn md__btn-link bgt p-0 m-0" @click.prevent="onClick">
    <img :src="icoCirclePlus" alt="plus" width="24" />
  </button>
</template>

<script>
import { icoCirclePlus } from "@/services/resources";

export default {
  name: "ButtonCirclePlusComponent",
  data() {
    return {
      icoCirclePlus
    };
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  }
};
</script>

<style lang="scss" scoped>
.icons img {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.13);
  }
}
</style>
