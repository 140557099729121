<template>
  <Scroll-Div width="100%" height="480px" class="px-4">
    <h6 class="text-center text-color-primary font-bold mb-4">
      ACEPTACIÓN DEL SERVICIO BOTÓN DE PAGOS
    </h6>
    <p>
      El SOLICITANTE en calidad de usuario de plataforma web MYDESSK, a quien en
      adelante se lo podrá denominar simplemente “EL USUARIO”, en virtud de
      haber aceptado los Términos y Condiciones de Uso de la página web y de
      haberse registrado en la plataforma.
    </p>

    <p>
      El Usuario ha suscrito la presente aceptación de servicio de botón de
      pagos, al tenor de las declaraciones y estipulaciones contenidas en las
      siguientes cláusulas:
    </p>

    <p>
      <strong>PRIMERA: ANTECEDENTES: </strong>
      MYDESSK otorga los servicios de botón de pagos, mediante el cual se pueden
      realizar operaciones y transacciones especiales a través de canales de
      comercio electrónico e internet, en las cuales se acepte como medio de
      pago las tarjetas de crédito.
    </p>

    <p>
      <strong>SEGUNDA: OBJETO DEL CONTRATO: </strong>
      Por medio del presente contrato, MYDESSK autoriza de manera libre y
      voluntaria que el USUARIO pueda establecer un mecanismo de cobros de sus
      bienes y/o servicios, en su propio canal web, mediante un link o enlace
      que redireccione a la plataforma MYSDESSK, para que le permita utilizar
      los servicios de “DATAWEB” o “Botón de Pagos” y “DATALINK”, adicionalmente
      los servicios de “PAYPHONE” que MYDESSK recibe por parte de DATAFAST.
    </p>

    <p>
      <strong
        >TERCERA: RESPONSABILIDADES Y OBLIGACIONES GENERALES DEL USUARIO.
        -</strong
      >
      Con relación al servicio entregado por MYDESSK, el USUARIO especialmente
      se compromete a:
      <br />
      <br />
      3.1 Respetar y cumplir las normas operativas que las ENTIDADES EMISORAS
      ASOCIADAS tienen para sus TARJETAS, las disposiciones establecidas en el
      Código Orgánico Monetario y Financiero, las resoluciones de los órganos de
      control, el estándar de la seguridad de datos para la industria de
      tarjetas de crédito, y demás normas que regulan el uso de las TARJETAS a
      nivel nacional e internacional.
      <br />
      <br />
      3.2 Vender a los tarjetahabientes todas las mercancías y/o servicios que
      ofrezcan al público al precio normal, marcado o publicado, sujeto a las
      rebajas concedidas o anunciadas y sin recargo alguno de conformidad a lo
      preceptuado en la Ley Orgánica de Defensa del Consumidor, la Codificación
      de Resoluciones de la Superintendencia de Bancos, Resoluciones de la Junta
      Bancaria, y Resoluciones de la Junta de Política y Regulación Monetaria y
      Financiera y demás normativa aplicable.
      <br />
      <br />
      3.3 No realizar auto consumos, es decir, ventas ficticias con las TARJETAS
      con el fin de obtener dinero en efectivo.
      <br />
      <br />
      3.4 No rechazar en ningún momento las TARJETAS, ni desalentar el uso de
      alguna en beneficio de otra, excepto en los casos que comprometan el
      correcto manejo de una venta segura, caso contrario podrá ser desafiliado
      de la red de tarjetas.
      <br />
      <br />
      3.5 Mantener la más estricta confidencialidad de la información contenida
      en las notas de cargo (vouchers) o que sea objeto de procesamiento. Dicha
      información se encuentra sujeta al sigilo y/o reserva bancaria, según los
      términos previstos en la ley.
      <br />
      <br />
      3.6 Mantener todos los materiales y registros que contengan información de
      los tarjetahabientes en forma segura, con acceso limitado al personal
      autorizado.
      <br />
      <br />
      <strong
        >Además, con relación a los servicios de PAYPHONE O DATAWEB (Botón de
        Pagos) y DATALINK, el USUARIO especialmente se compromete a:
      </strong>
      <br />
      <br />
      3.7.- Entregar al tarjetahabiente juntamente con el bien o servicio
      adquirido a través de los Canales de Comercio Electrónico la factura
      correspondiente, así como cumplir con lo dispuesto en el Reglamento de
      Comprobantes de Venta y Retención y la Ley de Régimen Tributario Interno.
      <br />
      <br />
      3.8 Custodiar por el tiempo establecido en la legislación vigente y en
      forma segura los soportes, comprobantes de venta y demás documentos
      operativos que contengan información sobre la cuenta y las operaciones de
      transacciones especiales realizadas por el tarjetahabiente, información
      que será considerada confidencial, sin embargo, deberá ser proporcionada a
      MYDESSK en caso de requerirla.
      <br />
      <br />
      3.9 Hacer conocer al Tarjetahabiente de manera clara y precisa que
      cualquier reclamo respecto a los productos o servicios que comercializa el
      USUARIO debe ser dirigido directamente a éste. El USUARIO se compromete a
      respetar los derechos y garantías establecidos en la Ley Orgánica de
      Defensa del Consumidor y en la Ley de Comercio Electrónico, Firmas
      Electrónicas y Mensajes de Datos.
      <br />
      <br />
      3.10 EL USUARIO, mantendrá absoluta reserva, frente a terceros, acerca de
      los datos del (los) tarjetahabiente(s) que obtenga por las transacciones
      realizadas por éstos con EL USUARIO.
      <br />
      <br />
      3.11 EL USUARIO mantendrá una dirección de correo electrónico disponible
      los 7 días de la semana las 24 horas del día, con la finalidad de que
      MYDESSK pueda remitir cualquier comunicación, incluso aquellas que haya
      recibido de parte de las entidades DATAFAST o ECUAPAYPHONE C.A.
      <br />
      <br />
      3.12 EL USUARIO no podrá ofrecer a través de sus Canales de Comercio
      Electrónico: Bienes y/o servicios relativos o vinculados a la
      participación del(los) tarjetahabiente(s) juegos de casino (blackjack,
      póker, ruletas, etc.), loterías, bingos, entre otros, ni tampoco bienes
      y/o servicios de entretenimiento para adultos (tales como material
      pornográfico, erótico, pornografía infantil, consultas psíquicas,
      esotéricas o similares), ni tampoco aquellas actividades sobre sustancias
      estupefacientes o psicotrópicas o que su comercialización se halle
      prohibida por normas legales o por autoridades competentes.
      <br />
      <br />
      3.13 EL USUARIO, realizará la publicidad, promoción e información de los
      bienes y servicios que ofrezca a través de los Canales de Comercio
      Electrónico, asegurándose que el consumidor pueda acceder a toda
      información disponible sobre el bien o servicio que ofrece en concordancia
      con la Ley Orgánica de Defensa al Consumidor, el Código Orgánico Integral
      Penal, así como reflejar en todo momento la existencia en inventario de
      los productos ofertados e informar sobre las políticas de entrega,
      reembolso y devoluciones que maneja el USUARIO.
      <br />
      <br />
      3.14. EL USUARIO declara expresamente que se subrogará y asumirá como
      propias todas las obligaciones que MYDESSK tenga con relación al servicio
      de botón de pagos, que recibe de DATAFAST o ECUAPAYPHONE C.A.,
      especialmente lo relacionado al pago de multas y sanciones.
    </p>
    <p>
      <strong>CUARTA: DECLARACIONES ESPECIALES: </strong>
      En el contexto del uso de los servicios recibidos, el USUARIO de manera
      libre y voluntaria realiza las siguientes declaraciones:
      <br />
      <br />
      4.1. EL USUARIO declara que conoce los requisitos técnicos y tecnológicos
      establecidos por DATAFAST o ECUAPAYPHONE C.A. con los que debe cumplir
      para acceder al servicio objeto de este contrato. En caso de no cumplir
      con los requerimientos el servicio no se podrá proveer y se exime a
      MYDESSK de responsabilidad.
      <br />
      <br />
      4.2. En el evento de que EL USUARIO, no cumpla con las disposiciones
      establecidas en este Contrato, el USUARIO declara que mantendrá indemne a
      MYDESSK en caso que DATAFAST o ECUAPAYPHONE C.A. le aplique o imponga
      alguna sanción, por algún evento relacionado con el uso de los servicios
      por parte de EL USUARIO. De esta forma, ante cualquier multa o sanción
      impuesta por DATAFAST o ECUAPAYPHONE C.A. a MYDESSK, el USUARIO declara
      expresamente que se encargará de cancelar su valor, sea cual fuere su
      monto y en las formas previstas para hacerlo, previa notificación o
      comunicación de MYDESSK.
      <br />
      <br />
      4.3. De presentarse reclamos debidamente probados por tarjetahabientes,
      alegando no haber efectuado el consumo, tratándose de transacciones
      autenticadas o sin seguridad, el USUARIO deberá resolver el inconveniente
      con el tarjetahabiente directamente. Si el Banco rechazare una
      transacción, MYDESSK, DATAFAST o ECUAPAYPHONE C.A., no tendrá
      responsabilidad alguna ni obligación de indemnizar al USUARIO por ningún
      concepto, renunciando éste a cualquier acción presente o futura.
      <br />
      <br />
      4.4. La autenticación del tarjetahabiente es de exclusiva responsabilidad
      del USUARIO quien asume total responsabilidad por los consumos efectuados
      a través de los canales de comercio electrónico. EL USUARIO, declara
      conocer que la autorización realizada por parte de las ENTIDADES se
      refiere única y exclusivamente a una autorización crediticia para que el
      tarjetahabiente realice su transacción, mas no se refiere a una
      autenticación de la tarjeta de crédito o débito. EL USUARIO, se encuentra
      obligado a procesar dichas transacciones mediante la página web de
      MYDESSK, que la declara como un medio seguro, por lo que libera a
      DATAFAST, ECUAPAYPHONE C.A. y/o a las ENTIDADES de toda responsabilidad
      derivada de dichas transacciones.
      <br />
      <br />
      4.5. Se deja expresa constancia que en ningún caso MYDESSK, DATAFAST,
      ECUAPAYPHONE C.A. o entidades emisores de las tarjetas de crédito tendrán
      responsabilidad frente a los tarjetahabientes o terceros respecto de los
      bienes o servicios comercializados por el USUARIO, ni serán responsables
      de su incumplimiento o cumplimiento defectuoso.
      <br />
      <br />
      4.6. EL USUARIO será el único responsable de los consumos efectuados por
      los tarjetahabientes a través de los Canales de Comercio Electrónico hasta
      la totalidad del pago de estos, por lo tanto, MYDESSK no asume ninguna
      responsabilidad en el sentido señalado.
      <br />
      <br />
      4.7. EL USUARIO se compromete con MYDESSK a observar las disposiciones del
      Código Orgánico Integral Penal en lo referente a la regulación del uso de
      información de Tarjetas de Crédito, Débito y otros pagos o intercepción
      ilegal de datos, Capítulo Tercero, Sección Tercera “Delitos contra la
      seguridad de los activos de información y comunicación” así como cumplir
      las siguientes normas y obligaciones. En este contexto, deberá abstenerse
      de realizar cualquier conducta que pudiera tipificarse como infracción,
      según la legislación penal.
    </p>
    <p>
      <strong
        >QUINTA: POLÍTICA DE RETENCIÓN DE VALORES POR USO DE BOTÓN DE PAGOS:
      </strong>
      El usuario declara y acepta que la venta de cualquier producto y/o
      servicio, que se cobre a través del botón de pagos de MYDESSK estará
      gravado con Impuesto a la Renta e Impuesto al Valor Agregado según los
      términos de la Ley Orgánica de Régimen Tributario Interno; se gravarán los
      cargos del uso de botón de pagos, los cargos de servicio por utilización
      tarjeta de crédito o débito, y, además, que MYDESSK tendrá derecho a
      retener un porcentaje por comisión, por la venta realizada. De igual
      forma, el valor por la compra realizada a través de la plataforma. será
      transferido por MYDESSK hacia la cuenta del usuario, luego de haber
      realizado las correspondientes retenciones, según el tipo de tarjeta o
      Banco.
      <br />
      Los valores que se podrán retener por MYDESSK, se detallan a continuación:
      <!-- table -->
      <br />
      <br />
      <strong>BOTON DE PAGOS DATAFAST </strong>
      <br />
      <table class="table-terms">
        <tr>
          <th>Retenciones por el servicio de botón de pago</th>
          <th>Porcentaje (%)</th>
        </tr>
        <tr>
          <td>Comisión de Datafast por servicio de botón de pagos</td>
          <td>$0.20 (valor fijo por cada transacción)</td>
        </tr>
        <tr>
          <td>Pago con tarjeta de crédito</td>
          <td>4.5% - 5% (depende de tarjeta y banco)</td>
        </tr>
        <tr>
          <td>Pago con tarjeta de débito</td>
          <td>2.24% - 3% (depende de tarjeta y banco)</td>
        </tr>
        <tr>
          <td>Retención de IVA</td>
          <td>70% (sobre el valor de IVA de la transacción)</td>
        </tr>
        <tr>
          <td>Retención de Impuesto a la renta</td>
          <td>2% (sobre el valor neto, sin incluir IVA)</td>
        </tr>
        <tr>
          <th>Retenciones del servicio de Mydessk</th>
          <th>Porcentaje (%)</th>
        </tr>
        <tr>
          <td>Comisión MYDESSK</td>
          <td>2,24% (Sobre el valor total, incluyendo IVA)</td>
        </tr>
        <tr>
          <td>IVA por servicio de MYDESSK</td>
          <td>12% del valor de Comisión de MYDESSK</td>
        </tr>
      </table>
      <br />
      <strong>BOTON DE PAGOS PAYPHONE</strong>
      <br />
      <table class="table-terms">
        <tr>
          <th>Retenciones por el servicio de botón de pago</th>
          <th>Porcentaje (%)</th>
        </tr>
        <tr>
          <td>Comisiones Bancarias</td>
          <td>6% + 0.60 ctvs (comisión bancaria de retiro)</td>
        </tr>
        <tr>
          <td>Comisión MYDESSK</td>
          <td>2,24% (Sobre el valor total, incluyendo IVA)</td>
        </tr>
        <tr>
          <td>IVA por servicio de MYDESSK</td>
          <td>12% del valor de Comisión de MYDESSK.</td>
        </tr>
      </table>
      <br />
      <br />
      El usuario declara expresamente que no tendrá nada que reclamar a MYDESSK
      por los valores que haya recibido de parte de MYDESKK, una vez hechas las
      retenciones antes indicadas, por lo que desde ya se encuentra de
      conformidad con los mismos.
    </p>
    <p>
      <strong>SEXTA: ACTIVIDAD DEL ESTABLECIMIENTO.- </strong>
      Por medio de la contratación de este servicio adicional EL USUARIO se
      compromete a comercializar los bienes y/o servicios detallados en la
      solicitud de afiliación presentada por el USUARIO a MYDESKK.
      <br />
      <br />
      Se aclara que MYDESSK no tiene control sobre la fabricación, importación,
      exportación, distribución o comercialización de los bienes y servicios
      ofrecidos por EL USUARIO. Por tanto, no avala su cantidad, calidad,
      idoneidad, seguridad o entrega.
    </p>
    <p>
      <strong>SEPTIMA: LICITUD DE FONDOS: </strong>
      En el marco del presente contrato, el USUARIO de manera libre y voluntaria
      declara que el origen de los fondos propios entregados por el USUARIO o
      sus clientes, utilizando sus páginas web, a través de sus canales de
      “DATAWEB” o “Botón de Pagos “, “DATALINK” o “PAYPHONE” proviene de
      actividades lícitas y que de ninguna manera están relacionados con
      actividades ilegales vinculadas con el lavado de activos, narcotráfico o
      financiamiento del terrorismo. En todo caso, el USUARIO a través del
      presente contrato, autoriza expresamente a MYDESSK y a cualquiera de las
      entidades con las que se haya afiliado, para realizar el análisis y las
      verificaciones que considere necesarias para corroborar la licitud de
      fondos.
      <br />
      <br />
      En caso que exista alguna irregularidad, el USUARIO declara que mantendrá
      indemne a MYDESSK, ECUAPAYPHONE C.A. y/o DATAFAST o las instituciones
      bancarias, sobre cualquier demanda o denuncia, en sede judicial o
      administrativa, que se llegase a intentar por el origen ilegal de los
      fondos que se han utilizado para los pagos a través de los servicios de
      botón de pagos, quien además deberá indemnizar por daños y perjuicios a
      MYDESSK en el evento que se llegasen a producir.
      <br />
      <br />
      Expresamente se aclara que MYDESSK no asumirá ningún tipo de
      responsabilidad, al ser considerado sólamente un intermediario de la
      utilización del servicio de “PAYPHONE”, “Dataweb” o “Datalink” que ofrece
      a EL USUARIO, quien ha aceptado los términos del presente contrato, por lo
      que se recalca que no asumirán ninguna responsabilidad por los servicios
      que ofrezca el USUARIO a sus clientes, ni por las transacciones realizadas
      por sus tarjetahabientes, de conformidad con el Art. 8 del Reglamento de
      la Ley de Comercio Electrónico, Firmas Electrónicas y Mensajes de Datos.
    </p>
    <p>
      <strong>OCTAVA: OBLIGACIONES DE LA PLATAFORMA MYDESSK:</strong>
      En virtud del presente contrato, MYDESSK se compromete a cumplir las
      obligaciones que se detallan a continuación:
      <br />
      <br />
      8.1. Permitir al usuario la utilización de los servicios de “PAYPHONE”,
      “DATAWEB” o “Botón de pagos” o “Datalink”, a través del link o enlace que
      direccione la compra a la plataforma de MYDESSK, que cuenta con el
      servicio proporcionado por DATAFAST y ECUAPAYPHONE C.A..
      <br />
      <br />
      8.2. Tener siempre operativa 24/7 la plataforma de pagos de MYDESSK para
      que el USUARIO pueda realizar las transacciones y compras a través del
      link. En todo caso, MYDESSK no responderá ante cualquier evento de caso
      fortuito o fuerza mayor, que deje inoperativo el servicio, por el tiempo
      que dure hasta su corrección.
      <br />
      <br />
      8.3. Contar con los permisos, autorizaciones y tecnología necesaria para
      el servicio de botón de pagos, así como realizar el respectivo
      mantenimiento de los equipos y aparatos proporcionados.
      <br />
      <br />
      8.4. Notificar a EL USUARIO cuando reciba algún comunicado de DATAFAST
      S.A. sobre alguna irregularidad, inconsistencia, devolución, rechazo o
      cualquier otro aviso, que esté relacionado al uso por parte de EL USUARIO,
      quien deberá asumir su responsabilidad.
      <br />
      <br />
      8.5. MYDESSK se reserva el derecho a terminar el presente contrato de
      forma unilateral, sin necesidad de requerimiento judicial de ninguna
      clase, cuando el USUARIO incumpla alguna de las obligaciones previstas en
      el presente acuerdo.
    </p>
    <p>
      <strong>NOVENA: PLAZO. - </strong>
      El plazo del presente contrato es indefinido a menos que alguna de las
      partes exprese por escrito su voluntad de darlo por terminado.
      <br />
      <br />
      El presente contrato, además, terminará al momento que el USUARIO termine
      su vínculo contractual con MYDESSK, al ser un contrato accesorio que
      depende de su condición de usuario o consumidor.
    </p>

    <p>
      <strong>DÉCIMA: INCUMPLIMIENTOS Y EFECTOS. - </strong>
      En el evento que MYDESSK haya sido efectivamente multado por algún
      incumplimiento realizado por el USUARIO, este último tendrá la obligación
      de indemnizar a MYDESSK dentro del término de 24 horas, previa
      comunicación escrita o electrónica del valor de la multa o el cargo. Para
      el pago de la multa, bastará que MYDESSK notifique electrónicamente su
      contenido a EL USUARIO, sin que sea necesario requerimiento judicial.
      <br />
      <br />
      Así también, si se confirmara el incumplimiento por parte del USUARIO de
      una o más de las estipulaciones de este contrato, MYDESSK podrá darlo por
      terminado y proceder a la desafiliación del USUARIO, sin reembolso alguno
      sobre el valor pagado a la plataforma por su afiliación.
    </p>
    <p>
      <strong>DÉCIMA PRIMERA: MODIFICACIONES AL CONTRATO: </strong>
      MYDESSK se reserva el derecho de modificar este contrato en cualquier
      momento, el cual será comunicado por correo electrónico al USUARIO, quien
      tendrá un término de setenta y dos (72) horas para remitir sus
      observaciones; caso contrario, se entenderá que ha aceptado dichas
      modificaciones, las cuales entrarán en vigencia para su implementación
      técnica y/u operativa, lo que será cumplido y respetado por el USUARIO,
      sin opción a alegar su desconocimiento.
    </p>
    <p>
      <strong>DÉCIMA SEGUNDA: NOTIFICACIONES ELECTRÓNICAS. - </strong>
      El USUARIO acepta expresamente que MYDESSK pueda remitir electrónicamente
      mediante correos electrónicos, a las direcciones fijadas en el registro,
      todo tipo de comunicaciones que se necesiten cursar en virtud de su
      relación comercial. En este sentido, las partes declaran que en todo lo
      relacionado a comunicaciones judiciales, notificaciones, inclusive la
      citación con la demanda, podrá ser remitida por vía correo electrónico, de
      conformidad con lo dispuesto en el Art. 55 numeral 1 del Código Orgánico
      General de Procesos. En este contexto, las partes declaran que recibirán
      todas las comunicaciones, incluyendo la citación con la demanda, en estas
      direcciones:

      <br />
      <br />
      Será obligación del USUARIO actualizar sus números de telefonía móvil o
      dirección de correo electrónico en caso de que estos hayan cambiado, por
      lo que libera desde ya a MYDESSK de todo tipo de responsabilidad que se
      pudiere derivar por no actualizar dicha información. En este último caso,
      se tendrán por bien hechas las notificaciones o envío de información que
      efectúe MYDESSK a las direcciones del USUARIO que tuviere registradas.
    </p>
    <p>
      <strong>DÉCIMA SEGUNDA: SOLUCIÓN DE CONTROVERSIAS. - </strong>
      En caso de que exista alguna discrepancia o controversia entre las partes,
      derivada del presente contrato y sus anexos, estos se comprometen a
      realizar las siguientes gestiones: En todo caso, para cualquier
      reclamación sobre la utilización de los servicios que brinda el portal web
      app.mydessk.com, que llegase a originar una demanda judicial, el usuario
      expresamente renuncia a su domicilio personal y se somete a la competencia
      de los jueces del cantón Guayaquil de la provincia del Guayas.
      <br />
      <br />
      Todas las notificaciones, requerimientos, peticiones y, en general,
      cualquier comunicación, incluyendo cualquier citación judicial, que se
      realicen en el marco de alguna demanda interpuesta por el usuario por el
      uso de la plataforma, por la prestación de servicios de la misma, deberán
      enviarse a la siguiente dirección electrónica:
      <a href="mailto:info@mydessk.com">info@mydessk.com.</a>
      <br />
      <br />
      Para constancia y ratificación de lo convenido, el usuario acepta los
      términos y condiciones del presente documento dando click al botón de
      aprobación.
    </p>
  </Scroll-Div>
</template>

<script>
export default {
  name: "TermsPayButton"
};
</script>

<style></style>
