<template>
  <el-popover
    ref="popoverDatesSelect"
    placement="bottom-start"
    width="350"
    trigger="click"
  >
    <template name="—">
      <p class="pb-2 d-inline-block m-0 font-bold text-color-blank">
        Buscar por fechas
      </p>
      <i
        class="el-icon-circle-close float-right cursor-point"
        @click="closePopoverDates"
      ></i>
      <el-radio
        v-for="(date, index) in filterDates"
        :key="index"
        v-model="rangeDates"
        :label="date.label"
        class="d-block my-2"
        >{{ date.title }}</el-radio
      >
      <el-date-picker
        v-model="customRangedates"
        @change="handleChangeCustomRangedates"
        format="dd-MM-yyyy"
        value-format="yyyy-MM-dd"
        type="daterange"
        v-if="rangeDates === 'dateRages'"
      ></el-date-picker>
    </template>
    <el-input
      slot="reference"
      :placeholder="placeholder"
      suffix-icon="el-icon-date"
      class="cursor-point"
      readonly
    >
    </el-input>
  </el-popover>
</template>

<script>
import {
  filterDates,
  currentDayDate,
  lastWeekFromDate,
  currentFromDate,
  currentToDate,
  lastFromMonthDate,
  lastToMonthDate,
  lastYearDate,
  lastFromYearDate,
  lastToYearDate
} from "@/services/DateServices";
export default {
  name: "FilterBetweenDatesSelect",
  data() {
    return {
      rangeDates: null,
      searchDates: null,
      customRangedates: null,
      selectDate: null,
      filterDates
    };
  },
  computed: {
    placeholder() {
      return this.selectDate || "Filtrar por fechas";
    }
  },
  watch: {
    keyRangeDates(value) {
      this.rangeDates = value;
    },
    rangeDates() {
      this.handleChangeRageDates();
    }
  },
  methods: {
    closePopoverDates() {
      this.$refs.popoverDatesSelect.doClose();
    },
    handleChangeRageDates() {
      this.searchDates = null;
      this.selectDate = null;

      switch (this.rangeDates) {
        case "currentDay":
          this.selectDate = "Hoy";
          this.searchDates = {
            from: currentDayDate,
            to: currentDayDate
          };
          break;
        case "lastWeek":
          this.selectDate = "Última semana";
          this.searchDates = {
            from: lastWeekFromDate,
            to: currentDayDate
          };
          break;
        case "currentMonth":
          this.selectDate = "Mes actual";
          this.searchDates = {
            from: currentFromDate,
            to: currentToDate
          };
          break;
        case "lastMonth":
          this.selectDate = "Mes anterior";
          this.searchDates = {
            from: lastFromMonthDate,
            to: lastToMonthDate
          };
          break;
        case "currentYear":
          this.selectDate = "Último año";
          this.searchDates = {
            from: lastYearDate,
            to: currentDayDate
          };
          break;
        case "lastYear":
          this.selectDate = "Año anterior";
          this.searchDates = {
            from: lastFromYearDate,
            to: lastToYearDate
          };
          break;
        case "dateRages":
          this.selectDate = "Rango de fechas";
          break;
        default:
          break;
      }
      if (this.rangeDates !== "dateRages") this.onAsyncDataForDates();
    },
    onAsyncDataForDates() {
      this.$emit("onAsyncDataForDates", {
        searchDates: {
          ...this.searchDates,
          ...{
            keyRangeDates: this.rangeDates
          }
        }
      });
      this.closePopoverDates();
    },
    handleChangeCustomRangedates() {
      this.searchDates = null;
      if (this.customRangedates)
        this.searchDates = {
          from: this.customRangedates[0],
          to: this.customRangedates[1]
        };
      this.onAsyncDataForDates();
    }
  },
  mounted() {
    if (typeof this.defaultMonthValue != "object") {
      this.rangeDates = "currentMonth";
      this.handleChangeRageDates();
    }
  },
  props: {
    keyRangeDates: {
      default: null,
      type: String
    },
    defaultMonthValue: {
      default: null,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.el-range-editor {
  width: 100% !important;
}
</style>
