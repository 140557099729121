<template>
  <div
    v-if="withholding"
    @click.stop="withholdingView"
    class="sale-document-body md__border border-radius bg-color-write p-3 mb-2 cursor-point"
  >
    <div class="sale-document-info">
      <div class="sale-document-description d-flex justify-content-between">
        <TextAvatarComponent :text="withholding.client.name" class="m-2" />
        <div class="info">
          <p class="m-0 text-color-primary str-limit-space">
            N° {{ withholding.identification_document }}
          </p>
          <p class="m-0 font-bold text-color-primary str-limit-space">
            {{ withholding.client.name }}
          </p>
          <p class="m-0">
            <small>
              <span class="">creado: {{ withholding.created_at }}</span>
            </small>
          </p>
        </div>
        <div class="sale-document-number">
          <h5 class="h5 font-bold">
            <FormatAmount :amount="withholding.total" />
          </h5>
        </div>
      </div>
    </div>

    <div
      class="sale-document-actions d-flex justify-content-between align-items-center mt-4"
    >
      <div class="status d-flex">
        <StatusReadDocumentsComponent
          :status="withholding.status"
          typeStatus="short"
        />
      </div>
      <div class="next"></div>
    </div>
  </div>
</template>

<script>
import TextAvatarComponent from "../../../components/TextAvatar";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import FormatAmount from "@/components/General/FormatAmount";

export default {
  name: "WithholdingCardComponent",
  computed: {},
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    withholdingView() {
      this.goRouter("sales.withholdings.view", {
        id: this.withholding.id
      });
    }
  },
  components: {
    TextAvatarComponent,
    StatusReadDocumentsComponent,
    FormatAmount
  },
  props: {
    withholding: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
