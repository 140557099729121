<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :with-header="withHeader"
    :size="size"
    :before-close="handleClose"
  >
    <section class="p-4">
      <h3
        class="h3 text-center font-semi-bold text-break text-color-primary mb-2"
      >
        Agregar información de cobro o pago
      </h3>
      <el-form :model="form" :rules="rules" ref="form" class="mt-5">
        <el-form-item prop="amount">
          <slot name="label">
            <label class="font-semi-bold m-0">Valor</label>
          </slot>
          <el-input placeholder="" v-model="form.amount"> </el-input>
        </el-form-item>
        <el-form-item prop="user_business_client_id">
          <SelectBusinessComponent
            title="Nombre del cliente"
            @onChangeSelect="onChangeSelectBusiness"
          />
        </el-form-item>
        <el-form-item prop="user_product_id">
          <slot name="label">
            <label class="font-semi-bold m-0">Productos</label>
          </slot>
          <SelectProductsComponent select @onSelectProduct="onSelectProduct" />
        </el-form-item>
        <el-form-item prop="detail">
          <slot name="label">
            <label class="font-semi-bold m-0">Detalle</label>
          </slot>
          <el-input placeholder="" v-model="form.detail"> </el-input>
        </el-form-item>

        <b-row>
          <b-col xs="12" sm="12">
            <ButtonPrimaryComponent
              class="center mt-4"
              title="Continuar"
              @onSaveClick="handleSaveClick"
            />
          </b-col>
        </b-row>
      </el-form>
    </section>
  </el-drawer>
</template>

<script>
import ButtonPrimaryComponent from "@/modules/dashboard/components/ButtonPrimary";
import SelectBusinessComponent from "../../../../components/SelectBusinessV2";
import SelectProductsComponent from "../../../../components/SelectProducts";
import Validate from "@/assets/validate";

export default {
  name: "PaymentAddComponent",
  data() {
    return {
      drawer: this.visible,
      form: {
        user_client_id: "",
        user_product_id: [],
        detail: "",
        amount: null
      },
      rules: {
        detail: [Validate.rules.required()],
        amount: [Validate.rules.required()],
        user_client_id: [Validate.rules.required("change")],
        user_product_id: [Validate.rules.required("change")]
      }
    };
  },
  watch: {
    visible(v) {
      this.drawer = v;
    }
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    },
    onChangeSelectBusiness({ client }) {
      const { id } = client;
      this.form.user_client_id = id;
    },
    onSelectProduct({ products }) {
      this.form.user_product_id = products;
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          // this.$store.dispatch("toggleRequestLoading");
          // try {
          //   }
          // } catch (error) {
          //   return false;
          // }
        }
      });
    }
  },
  props: {
    title: {
      default: ""
    },
    visible: {
      default: false
    },
    direction: {
      default: "rtl"
    },
    withHeader: {
      default: false
    },
    size: {
      default: "40%"
    }
  },
  components: {
    ButtonPrimaryComponent,
    SelectBusinessComponent,
    SelectProductsComponent
  }
};
</script>

<style lang="scss" scoped></style>
