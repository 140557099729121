<template>
  <b-modal
    size="xl"
    v-model="internalShowModal"
    @close="handleClose"
    :body-class="bodyClass"
    @cancel="handleClose"
    :ok-variant="okVariant"
    :cancel-variant="cancelVariant"
  >
    <div class="" v-if="invoice">
      <b-container fluid class="pb-4 px-lg-4 pt-4">
        <b-row>
          <b-col md="6" lg="4" xl="3">
            <BusinessLogo />
          </b-col>
          <b-col md="6" lg="4" xl="3">
            <div class="info">
              <h4 class="h4 font-bold m-0">N° {{ invoice.number_invoice }}</h4>
              <h2 class="h2 font-bold m-0">
                {{ getUser.user_business.social_reason }}
              </h2>
              <p class="text-break">
                RUC: {{ getUser.user_business.business_ruc }}
              </p>
              <p class="text-break">
                {{ getUser.user_business.business_address }}
              </p>
            </div>
          </b-col>
          <b-col md="12" lg="12" xl="6">
            <div class="card-quotes-view-info">
              <b-row>
                <b-col sm="12" md="4" lg="4">
                  <label class="font-bold d-block">Fecha de emisión</label>
                  <p class="m-0 text-brea">
                    {{ invoice.emission_date_for_view }}
                  </p>
                </b-col>
                <b-col sm="12" md="4" lg="4">
                  <label class="font-bold d-block">Punto de emisión</label>
                  <p
                    class="m-0 text-brea"
                    v-if="invoice.user_business_e_point_id"
                  >
                    {{ invoice.emission_point.code }}
                  </p>
                </b-col>
                <b-col sm="12" md="4" lg="4">
                  <label class="font-bold d-block mb-2">Status</label>
                  <StatusReadDocumentsComponent :status.sync="invoice.status" />
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col sm="12" md="4" lg="4">
                  <label class="font-bold d-block">Cliente</label>
                  <p class="m-0 text-brea">{{ invoice.client.name }}</p>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4">
                  <label class="font-bold d-block">Documento</label>
                  <p class="m-0 text-brea">{{ invoice.client.document }}</p>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4">
                  <label class="font-bold d-block">Correo</label>
                  <p class="m-0 text-break">
                    {{ invoice.client.email }}
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <hr />
        <div class="card-quotes-view-body mt-5">
          <div class="card-quotes-view-body-products">
            <div class="card-quotes-view-table table-items">
              <b-row class="table-items-header">
                <b-col xs="12" sm="12" md="2">
                  <h6 class="h6 font-bold mb-0 ml-2">Código</h6>
                </b-col>
                <b-col xs="12" sm="12" md="4">
                  <h6 class="h6 font-bold mb-0">Detalle</h6>
                </b-col>
                <b-col xs="12" sm="4" md="2">
                  <h6 class="h6 font-bold mb-0">Cantidad</h6>
                </b-col>
                <b-col xs="12" sm="4" md="2">
                  <h6 class="h6 font-bold mb-0">Precio U.</h6>
                </b-col>
                <b-col xs="12" sm="4" md="2">
                  <h6 class="h6 font-bold mb-0">Subtotal</h6>
                </b-col>
              </b-row>
            </div>

            <b-row
              class="mt-4 d-flex"
              v-for="(product, index) in invoice.products"
              :key="index"
            >
              <b-col xs="12" sm="12" md="2">
                <p class="m-0 ml-2 text-break">{{ product.product.code }}</p>
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <p class="m-0">
                  <strong>{{ product.product.name }}</strong> <br />
                  <br />
                  <span
                    v-html="product.product_detail"
                    class="text-pre-wrap"
                  ></span>
                </p>
              </b-col>
              <b-col xs="12" sm="4" md="2">
                <p class="m-0">{{ product.product_quantity }}</p>
              </b-col>
              <b-col xs="12" sm="4" md="2">
                <p class="m-0">${{ product.product_amount }}</p>
              </b-col>
              <b-col xs="12" sm="4" md="2">
                <p class="m-0">
                  ${{
                    handleCalculateSubTotal(
                      product.product_quantity,
                      product.product_amount
                    )
                  }}
                </p>
              </b-col>
            </b-row>
          </div>
          <hr />
        </div>
        <b-row>
          <b-col md="8">
            <!-- <div style="max-width: 600px;">
            <b-row class="border">
              <b-col md="6" class="bg-color-card amount border-right pt-3">
                <label class="d-block">Total Pagado</label>
                <p>To Do</p>
              </b-col>
              <b-col md="6" class="amount pt-3">
                <label class="d-block">Total Pagado</label>
                <p>To Do</p>
              </b-col>
            </b-row>
          </div> -->

            <div class="mt-4" style="max-width: 600px;">
              <b-row class="border rounded">
                <b-col>
                  <h5 class="h5 font-semi-bold d-block p-2">
                    Forma de pago
                  </h5>
                  <hr />
                  {{ invoice.sri_forms_payment.title }}

                  <h5 class="h5 font-semi-bold d-block mt-4">
                    Información adicional
                  </h5>
                  <b-row
                    v-for="(additional, index) in invoice.additional_extras"
                    :key="index"
                  >
                    <b-col>{{
                      `${additional.name} - ${additional.description}`
                    }}</b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col>
            <b-card class="bg-color-card">
              <DocumentCalculator
                readOnly
                :setProducts="invoice.products"
                :setDisc="invoice.discount"
                :setServ="invoice.service"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import BusinessLogo from "@/components/Business/BusinessLogo";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import DocumentCalculator from "../../../components/DocumentCalculator";
import { parseFloatFormat } from "@/assets/utils/format";

export default {
  name: "InvoicePreviewComponent",
  data() {
    return {
      internalShowModal: false,
      bodyClass: "modal-custom",
      okVariant: "btn md__btn-primary",
      cancelVariant: "btn md__btn-secondary"
    };
  },
  props: {
    invoice: {
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    showModal(newVal) {
      // Actualiza la propiedad de datos cuando cambia la prop
      this.internalShowModal = newVal;
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCalculateSubTotal(quantity, price) {
      return parseFloatFormat(quantity * price);
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  components: {
    StatusReadDocumentsComponent,
    BusinessLogo,
    DocumentCalculator
  }
};
</script>
