<template>
  <WrapperTooltipComponent :content="formatStatus">
    <el-tag
      type="default"
      :class="`status ${status} border-radius text-capitalize font-bold`"
    >
      <span v-if="typeStatus == 'short'"> {{ formatStatus.charAt(0) }}</span>
      <span v-else>{{ formatStatus }}</span></el-tag
    >
  </WrapperTooltipComponent>
</template>

<script>
import WrapperTooltipComponent from "./WrapperTooltip";

export default {
  name: "StatusReadDocumentsComponent",
  computed: {
    formatStatus() {
      switch (this.status) {
        case "draft":
          return "Borrador";
        case "pending":
          return "Pendiente";
        case "success":
          return "Aprobada";
        case "nuled":
          return "Anulada";
        case "failed":
          return "Fallida";
        case "deny":
          return "No aprobada";
        default:
          return this.status;
      }
    }
  },
  components: {
    WrapperTooltipComponent
  },
  props: {
    status: {
      default: null
    },
    typeStatus: {
      default: "large"
    }
  }
};
</script>

<style lang="scss" scoped></style>
