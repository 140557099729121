<template>
  <div>
    <perfect-scrollbar
      ref="wrapperScrollbar"
      :style="{ height: `${height}` }"
      @ps-scroll-down="scrollingEnd"
    >
      <slot></slot>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "WrapperScrollbarComponent",
  methods: {
    scrollingEnd() {
      if (this.$refs.wrapperScrollbar.ps.reach.y === "end")
        this.$emit("scrollingEnd");
    }
  },
  props: {
    height: {}
  }
};
</script>

<style lang="scss" scoped>
.ps {
  padding: 0 0.5rem;
}
</style>
