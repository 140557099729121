<template>
  <el-dropdown @command="handleCommand">
    <button class="btn md__btn-primary">
      Nueva <i class="el-icon-arrow-down el-icon--right"></i>
    </button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="sales.quotes.add">Cotización</el-dropdown-item>
      <el-dropdown-item command="sales.invoices.add">Factura</el-dropdown-item>
      <el-dropdown-item command="sales.liquidations.add"
        >Liquidación Compra</el-dropdown-item
      >
      <el-dropdown-item command="sales.creditNote.add"
        >Nota de crédito</el-dropdown-item
      >
      <el-dropdown-item command="sales.debitNote.add"
        >Nota de debito</el-dropdown-item
      >
      <el-dropdown-item command="sales.withholdings.add"
        >Retención</el-dropdown-item
      >
      <!-- <el-dropdown-item command="quote">Cobros</el-dropdown-item> -->
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "ButtonAddSaleDocumentsComponent",
  methods: {
    handleCommand(command) {
      this.goRouter(command);
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    }
  }
};
</script>

<style lang="scss" scoped>
.md__btn-primary {
  padding: 0 1rem !important;
}
</style>
