<template>
  <el-dialog
    :visible.sync="visibleDialog"
    fullscreen
    :close-on-click-modal="false"
    @close="onCloseDialog"
  >
    <template slot="title">
      <LogoComponent class="" />
    </template>

    <div class="wrapper__step-content step-payment-upload-doc mt-4">
      <el-steps :active="active">
        <el-step></el-step>
        <el-step></el-step>
        <el-step></el-step>
      </el-steps>

      <div class="wrapper__step-body mt-5">
        <div class="" v-show="active === 0">
          <TermsPayButton />
          <div
            class="d-flex justify-content-center align-items-center flex-column mt-5"
          >
            <el-radio
              label="true"
              v-model="form.conditions"
              class="text-color-secondary font-bold"
            >
              Aceptar los términos del contrato
            </el-radio>
          </div>
        </div>
        <div class="" v-show="active === 1">
          <h6 class="text-center text-color-primary font-bold">
            Carga tus documentos
          </h6>
          <el-tabs v-model="tabActiveDocs" class="my-5">
            <el-tab-pane label="Persona Natural" name="Personal">
              <b-row>
                <b-col xs="12" sm="12" md="6">
                  <p class="text-center font-bold my-4">
                    RUC (Registro unico de contribuyentes)
                  </p>
                  <PaymentDocUp
                    @onAdd="onFilesRuc"
                    @onRemove="onRemoveFile('file_ruc')"
                  />
                </b-col>
                <b-col xs="12" sm="12" md="6">
                  <p class="text-center font-bold my-4">
                    Cédula y Papeleta de votación
                  </p>
                  <PaymentDocUp
                    @onAdd="onFilesDni"
                    @onRemove="onRemoveFile('file_dni')"
                  />
                </b-col>
              </b-row>
            </el-tab-pane>
            <el-tab-pane label="Empresa" name="Empresa">
              <b-row>
                <b-col xs="12" sm="12" md="4">
                  <p class="text-center font-bold my-4">
                    RUC (Registro unico de contribuyentes)
                  </p>
                  <PaymentDocUp
                    @onAdd="onFilesRuc"
                    @onRemove="onRemoveFile('file_ruc')"
                  />
                </b-col>
                <b-col xs="12" sm="12" md="4">
                  <p class="text-center font-bold my-4">
                    Cédula y Papeleta de votación
                  </p>
                  <PaymentDocUp
                    @onAdd="onFilesDni"
                    @onRemove="onRemoveFile('file_dni')"
                  />
                </b-col>
                <b-col xs="12" sm="12" md="4">
                  <p class="text-center font-bold my-4">
                    Nombramiento de Representante Legal
                  </p>
                  <PaymentDocUp
                    @onAdd="onFilesContract"
                    @onRemove="onRemoveFile('file_contract')"
                  />
                </b-col>
              </b-row>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="" v-show="active === 2">
          <h6 class="text-center text-color-primary font-bold">
            Complete la información de su negocio
          </h6>
          <b-row class="my-4">
            <b-col>
              <b-row>
                <b-col>
                  <label class="text-color-primary font-bold d-block mb-2 mt-4"
                    >Seleccionar tipo</label
                  >
                  <el-select
                    placeholder="Seleccionar"
                    v-model="form.activity_sales"
                  >
                    <el-option
                      v-for="type in businessActivitySales"
                      :key="type.id"
                      :label="type.name"
                      :value="type.name"
                    ></el-option>
                  </el-select>
                </b-col>
              </b-row>

              <b-row>
                <b-col xs="12" sm="12" md="6">
                  <label class="text-color-primary font-bold d-block mb-2 mt-4"
                    >Cantidad de ventas por mes (Apróximado)</label
                  >
                  <el-input
                    v-model="form.quantity_sales"
                    placeholder="0"
                  ></el-input>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                  <label class="text-color-primary font-bold d-block mb-2 mt-4"
                    >Ingresos por mes (Apróximado)</label
                  >
                  <el-input
                    v-model="form.earning_sales"
                    placeholder="$100"
                  ></el-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <label class="text-color-primary font-bold d-block mb-2 mt-4"
                    >Detalle brevemente su negocio</label
                  >
                  <el-input
                    v-model="form.short_description"
                    placeholder="Modelo de negocio, los servicios o productos que ofrece..."
                  ></el-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <template v-if="stepComplete">
          <div
            class="d-flex justify-content-center align-items-center flex-column h-100"
          >
            <img :src="icoCobrar" alt="" class="my-4" />
            <h2 class="h2 font-semi-bold text-color-secondary">
              ¡Felicidades!
            </h2>
            <h4 class="h4 mt-2">
              Ha finlizado el proceso de registro de botón de pagos Mydessk
            </h4>
            <h4 class="h4 font-bold mt-4 text-color-primary">
              Comienza a cobrar a tus clientes
            </h4>
          </div>
        </template>

        <div
          class="d-flex justify-content-center align-items-center flex-column mt-5"
        >
          <ButtonPrimary title="Continuar" @onClick="nextStep" class="my-2" />
          <ButtonText
            text="Regresar"
            @onClick="backStep"
            class="my-2"
            v-if="!stepInit && !stepComplete"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { icoCobrar } from "@/services/resources";
import PaymentDocUp from "./PaymentDocUp";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import ButtonText from "@/components/Buttons/ButtonText";
import TermsPayButton from "@/components/General/TermsPayButton";
import LogoComponent from "@/components/Logo/Logo";
import { businessActivitySales } from "@/services/Utils";
import paymentService from "../services/paymentService";

export default {
  name: "PaymentUploadDocument",
  data() {
    return {
      businessActivitySales,
      icoCobrar,
      visibleDialog: this.visible,
      active: 0,
      tabActiveDocs: "Personal",
      formData: new FormData(),
      form: {
        activity_sales: null,
        quantity_sales: null,
        earning_sales: null,
        short_description: null,
        conditions: null,

        file_ruc: null,
        file_dni: null,
        file_contract: null
      }
    };
  },
  computed: {
    stepComplete() {
      return this.active === 3;
    },
    stepInit() {
      return this.active === 0;
    }
  },
  watch: {
    visible(v) {
      this.visibleDialog = v;
    }
  },
  methods: {
    onFilesRuc({ file }) {
      this.form.file_ruc = file?.raw;
    },
    onFilesDni({ file }) {
      this.form.file_dni = file?.raw;
    },
    onFilesContract({ file }) {
      this.form.file_contract = file?.raw;
    },
    onRemoveFile(key) {
      this.form[key] = null;
    },
    nextStep() {
      const {
        activity_sales,
        quantity_sales,
        earning_sales,
        short_description,
        conditions,
        file_ruc,
        file_dni,
        file_contract
      } = this.form;

      switch (this.active) {
        case 0:
          if (!conditions) {
            this.notifications("Debes aceptar los términos del contrato");
            return false;
          }
          break;
        case 1:
          switch (this.tabActiveDocs) {
            case "Personal":
              if (!file_ruc || !file_dni) {
                this.notifications(
                  "Debes cargar los documentos personales solicitados"
                );
                return false;
              }
              break;
            case "Empresa":
              if (!file_ruc || !file_dni || !file_contract) {
                this.notifications(
                  "Debes cargar los documentos de empresa solicitados"
                );
                return false;
              }
              break;
            default:
              return false;
          }
          break;
        case 2:
          if (
            !activity_sales ||
            !quantity_sales ||
            !earning_sales ||
            !short_description
          ) {
            this.notifications("Debes completar la información del su negocio");
          } else {
            this.saveUploadDocuments();
          }
          return false;

        case 3:
          this.onCloseDialog();
          return false;
        default:
          break;
      }
      this.active += 1;
    },
    backStep() {
      this.active -= 1;
    },
    notifications(message) {
      this.$notifications.warning({
        message
      });
    },
    onCloseDialog() {
      this.$emit("onClose");
    },
    async saveUploadDocuments() {
      const {
        activity_sales,
        quantity_sales,
        earning_sales,
        short_description,
        file_ruc,
        file_dni,
        file_contract
      } = this.form;
      try {
        this.$store.dispatch("toggleRequestLoading");
        this.formData.append("personality", this.tabActiveDocs);
        this.formData.append("activity_sales", activity_sales);
        this.formData.append("quantity_sales", quantity_sales);
        this.formData.append("earning_sales", earning_sales);
        this.formData.append("short_description", short_description);
        this.formData.append("file_ruc", file_ruc);
        this.formData.append("file_dni", file_dni);
        this.formData.append("file_contract", file_contract);

        let response = await paymentService
          .addPayLinkUploadDocuments(this.formData)
          .finally(() => this.$store.dispatch("toggleRequestLoading"));
        const { success } = response.data;

        if (success) {
          this.active += 1;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    PaymentDocUp,
    ButtonPrimary,
    ButtonText,
    TermsPayButton,
    LogoComponent
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
