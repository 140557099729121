<template>
  <div>
    <div
      v-if="liquidation"
      @click.stop.prevent="liquidationView"
      class="sale-document-body md__border border-radius bg-color-write p-3 mb-2 cursor-point"
    >
      <div class="sale-document-info">
        <div class="sale-document-description d-flex justify-content-between">
          <TextAvatarComponent :text="liquidation.business_name" class="m-2" />
          <div class="info">
            <p class="m-0 text-color-primary str-limit-space">
              N° {{ liquidation.number_liquidation }}
            </p>
            <p class="m-0 font-bold text-color-primary str-limit-space">
              {{ liquidation.client.name }}
            </p>
            <p class="m-0">
              <small
                ><span class="">creado: {{ liquidation.created_at }}</span>
              </small>
            </p>
          </div>
          <div class="sale-document-number">
            <h5 class="h5 font-bold">
              <FormatAmount :amount="liquidation.total" />
            </h5>
          </div>
        </div>
      </div>

      <div
        class="sale-document-actions d-flex justify-content-between align-items-center mt-4"
      >
        <div class="status d-flex">
          <StatusReadDocumentsComponent
            :status="liquidation.status"
            typeStatus="short"
          />
          <CheckSuccessfully
            class="ml-2"
            :success="liquidation.is_paid"
            text="Pagada"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextAvatarComponent from "../../../components/TextAvatar";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import CheckSuccessfully from "@/components/General/CheckSuccessfully";
import FormatAmount from "@/components/General/FormatAmount";

export default {
  name: "LiquidationCardComponent",
  data() {
    return {
      showDialogPayLink: false,
      paymentLink: null,
      //  icoWooCommerce,
      showEnablePaylink: false
    };
  },
  computed: {
    isStatusNuled() {
      return this.liquidation.status === "nuled";
    },
    isShowPayLink() {
      return !this.isStatusNuled && !this.liquidation?.is_paid;
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    liquidationView() {
      this.goRouter("sales.liquidations.view", {
        id: this.liquidation.id
      });
    },
    onDialogPayLinkClose() {
      this.showDialogPayLink = false;
    },
    onClosePaymentUpDoc() {
      this.showEnablePaylink = false;
    }
  },
  components: {
    TextAvatarComponent,
    StatusReadDocumentsComponent,
    CheckSuccessfully,
    FormatAmount
  },
  props: {
    liquidation: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
