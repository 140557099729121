<template>
  <div id="icon-more-option" :class="[size, theme]">
    <i class="el-icon-more"></i>
  </div>
</template>

<script>
export default {
  name: "IconMoreOptionComponent",
  data() {
    return {};
  },
  props: {
    size: {
      default: "medium"
    },
    theme: {
      default: "blue"
    }
  }
};
</script>

<style lang="scss" scoped>
#icon-more-option {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    transform: rotate(90deg);
  }
  &.small {
    height: 40px;
    width: 40px;

    i {
      font-size: 1.2rem;
    }
  }
  &.medium {
    height: 60px;
    width: 60px;

    i {
      font-size: 2rem;
    }
  }
  &.large {
    height: 80px;
    width: 80px;

    i {
      font-size: 3rem;
    }
  }

  &.blue {
    color: #34485e;

    &:hover {
      background-color: #e4eaf6;
    }
  }

  &.white {
    color: #ffffff;

    &:hover {
      color: #34485e;
      background-color: #e4eaf6;
    }
  }
}
</style>
