<template>
  <SkeletonTheme color="#e3e3e3" highlight="#c3c3c3">
    <Skeleton :height="height" width="100%" />
  </SkeletonTheme>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

export default {
  name: "SkeletonOneCardComponent",
  components: {
    Skeleton,
    SkeletonTheme
  },
  props: {
    height: {
      default: "160px"
    }
  }
};
</script>

<style lang="scss" scoped></style>
