<template>
  <div>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        <IconMoreOptionComponent size="small" theme="blue" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="invoice-preview">
          <img :src="icoPreview" alt="" /> Previsualizar
        </el-dropdown-item>
        <template v-if="status === 'success'">
          <el-dropdown-item command="invoice-paylink">
            <img :src="icoPaylinkNew" alt="" /> Link de pago
          </el-dropdown-item>

          <el-dropdown-item command="invoice-nuled">
            <img :src="iconNuled" alt="" /> Anular factura
          </el-dropdown-item>
        </template>

        <template v-if="status === 'draft' || status === 'failed'">
          <el-dropdown-item command="invoice-signature">
            <img :src="icoSignature" alt="" /> Firmar
          </el-dropdown-item>
          <el-dropdown-item command="invoice-edit">
            <img :src="iconEdit" alt="" /> Editar
          </el-dropdown-item>
          <el-dropdown-item command="invoice-delete">
            <img :src="iconDelete" alt="" /> Eliminar
          </el-dropdown-item>
        </template>

        <el-dropdown-item command="invoice-send">
          <img :src="iconSend" alt="" /> Enviar documento
        </el-dropdown-item>

        <el-dropdown-item command="invoice-download">
          <img :src="iconDownload" alt="" class="img-sm" /> Descargar PDF
        </el-dropdown-item>

        <el-dropdown-item command="invoice-paid">
          <img :src="iconCheck" alt="" /> {{ textOptionIsPaid }} como pagada
        </el-dropdown-item>

        <el-dropdown-item command="invoice-print">
          <img :src="icoPrint" alt="" /> Vista de impresión
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog :visible.sync="invoicePrint">
      <div class="d-flex justify-content-center">
        <print-invoice :invoice="invoice" id="printInvoice" />
      </div>
      <div class="d-flex justify-content-center align-items-center mt-5">
        <button type="button" class="btn md__btn-primary mr-4" @click="print">
          Imprimir
        </button>
      </div>
    </el-dialog>

    <el-dialog
      width="200"
      title=""
      :visible.sync="invoiceSRIOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-signature">
        <h4 class="h4 text-center font-semi-bold text-break text-color-primary">
          ¿Desea firmar y enviar el documento al SRI?
        </h4>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <button
            type="button"
            class="btn md__btn-third-secondary mr-4"
            @click="toogleModalInvoiceSRI"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Confirmar"
            @onSaveClick="sendInvoiceSRI"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="80%"
      title=""
      :visible.sync="invoiceNuledOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-nuled text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea anular la factura?
        </h4>

        <p class="text-color-primary">
          Para completar todo el proceso de anulación la factura debe ser
          anulada en el
          <LinkSRI />
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalInvoiceNuled"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Anular"
            @onSaveClick="onInvoiceNuled"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="200"
      title=""
      :visible.sync="invoiceDeleteOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-delete text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea eliminar la factura?
        </h4>

        <p class="text-color-primary text-break">
          Recuerde que solo puede eliminar las facturas en borrador.
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalInvoiceDelete"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Eliminar"
            @onSaveClick="onInvoiceDelete"
          />
        </div>
      </div>
    </el-dialog>

    <!-- <el-dialog width="80%" :visible.sync="invoicePreview"> -->
    <Preview
      :invoice="invoice"
      :showModal="invoicePreview"
      @close="handleCommand('invoice-preview')"
    />
    <!-- </el-dialog> -->

    <WrapperSendDocumentDrawer
      @onDocumentSend="onDocumentSend"
      @onCloseDrawer="onCloseDocumentSendDrawer"
      :visibleDocumentSend="visibleDocumentSend"
      :isLoadingConfigTexts="false"
      :toMails="toMails"
    />
  </div>
</template>
<style lang="scss" scoped>
.img-sm {
  width: 0.8rem !important;
}
</style>
<script>
import WrapperSendDocumentDrawer from "../../../../components/WrapperSendDocumentDrawer";
import invoiceService from "../services/invoiceService";
import PrintInvoice from "./PrintInvoice.vue";
import IconMoreOptionComponent from "@/components/General/IconMoreOption";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import Preview from "./InvoicePreview.vue";
import LinkSRI from "@/components/General/LinkSRI";

import {
  icoNotifyDanger,
  iconEdit,
  iconDelete,
  iconNuled,
  iconSend,
  iconHistory,
  iconDownload,
  iconCheck,
  icoSignature,
  icoPreview,
  icoPaylinkNew,
  icoPrint
} from "@/services/resources";
import { downloadFile } from "@/assets/utils/files";

export default {
  name: "InvoiceOptionsByStatus",
  data() {
    return {
      icoNotifyDanger,
      iconEdit,
      iconDelete,
      iconNuled,
      iconSend,
      iconHistory,
      iconDownload,
      iconCheck,
      icoSignature,
      icoPreview,
      icoPaylinkNew,
      icoPrint,
      invoiceSRIOuterVisible: false,
      invoiceNuledOuterVisible: false,
      invoiceDeleteOuterVisible: false,
      visibleDocumentSend: false,
      visibleDocumentHistoryEmail: false,
      invoice: null,
      invoicePrint: false,
      invoicePreview: false,
      toMails: []
    };
  },
  props: ["status", "id", "is_paid"],
  computed: {
    textOptionIsPaid() {
      return this.is_paid ? "Desmarcar" : "Marcar";
    }
  },
  methods: {
    print() {
      const prtHtml = document.getElementById("printInvoice").innerHTML;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
                  body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";}
                  .d-flex { display: -ms-flexbox !important; display: -webkit-box !important; display: flex !important;}
                  .justify-content-center { -ms-flex-pack: center !important;  -webkit-box-pack: center !important; justify-content: center !important;}
                  .justify-content-between {  -ms-flex-pack: justify !important; -webkit-box-pack: justify !important; justify-content: space-between !important;}
                  .mt-4, .my-4 { margin-top: 1.5rem !important; }
                  .m-0 { margin: 0 !important; }
                  .m-2 { margin: 0.5rem !important; }
                  .mt-2, .my-2 { margin-top: 0.5rem !important; }
                  .font-semi-bold { font-weight: 600; }
                  h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.5rem; }
                  .font-bold { font-weight: 700; }
                  p { margin: 0; }
                  .text-pre-wrap { white-space: pre-wrap; word-wrap: break-word; }
                  .access_key { font-size: 12px !important; }
            </style>
          </head>
          <body>
            <div id="dataPrint">
              ${prtHtml}
            </div>
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    toogleModalInvoiceSRI() {
      this.invoiceSRIOuterVisible = !this.invoiceSRIOuterVisible;
    },

    async sendInvoiceSRI() {
      this.$store.dispatch("toggleRequestLoading", {
        text:
          "Por favor espere un momento, su documento esta siendo enviado al SRI."
      });
      try {
        this.toogleModalInvoiceSRI();
        let response = await invoiceService
          .sendInvoiceSRI({
            user_business_invoice_id: this.invoice.id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        } else {
          this.$notifications.error({
            message
          });
        }
        this.$store.dispatch("sendNotification", true);
        this.invoice = data;
      } catch (error) {
        return false;
      }
    },
    async getInvoice(invoiceId) {
      try {
        let response = await invoiceService
          .getInvoceId(invoiceId)
          .finally(() => {});
        if (response) {
          this.invoice = response.data.data;
        }
      } catch (error) {
        return false;
      }
    },
    async onInvoiceNuled() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: invoiceId } = this.invoice;
        let response = await invoiceService
          .updateInvoiceStatus({
            user_business_invoice_id: invoiceId
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalInvoiceNuled();
          });
        const { success, data, message } = response.data;
        if (success) {
          this.invoice = data;
          this.$notifications.success({
            message
          });
        }
        this.$store.dispatch("sendNotification", true);
      } catch (error) {
        return false;
      }
    },
    async onInvoiceDelete() {
      try {
        const { id: invoiceId } = this.invoice;
        let response = await invoiceService
          .deleteInvoceId(invoiceId)
          .finally(() => {
            this.toggleModalInvoiceDelete();
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        }
        this.$store.dispatch("sendNotification", true);
      } catch (error) {
        return false;
      }
    },

    async handleCommand(command) {
      if (this.invoice === null) {
        await this.getInvoice(this.id);
      }

      switch (command) {
        case "invoice-nuled":
          this.toggleModalInvoiceNuled();
          break;
        case "invoice-preview":
          this.invoicePreview = !this.invoicePreview;
          break;
        case "invoice-signature":
          this.toogleModalInvoiceSRI();
          break;
        case "invoice-paylink":
          this.$emit("payLink");
          break;
        case "invoice-edit":
          this.$emit("goPage", "sales.invoices.edit", {
            id: this.id
          });
          break;
        case "invoice-send":
          this.onOpenDocumentSendDrawer();
          break;
        case "invoice-history-email":
          this.visibleDocumentHistoryEmail = true;
          break;
        case "invoice-delete":
          this.toggleModalInvoiceDelete();
          break;
        case "invoice-download":
          this.invoiceDownload();
          break;
        case "invoice-paid":
          this.invoicePaid();
          break;
        case "invoice-print":
          this.invoicePrint = true;
          break;
      }
    },

    async invoicePaid() {
      try {
        const { is_paid, id: invoiceId } = this.invoice;
        let response = await invoiceService
          .invoicePaid({
            user_business_invoice_id: invoiceId,
            is_paid: !is_paid
          })
          .finally(() => {});
        const { success, message, data } = response.data;
        if (success) {
          this.invoice = data;
          this.$notifications.success({
            message
          });
          this.$store.dispatch("sendNotification", true);
        }
      } catch (error) {
        return false;
      }
    },
    async invoiceDownload() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { number_invoice, id } = this.invoice;
        let response = await invoiceService
          .invoiceDownload({
            id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { data } = response;
        if (data) {
          downloadFile(data, `Factura - #${number_invoice}`);
        }
      } catch (error) {
        return false;
      }
    },
    async sendInvoiceEmail({ data }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await invoiceService
          .sendInvoiceEmail({
            data
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.visibleDocumentSend = false;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    toggleModalInvoiceNuled() {
      this.invoiceNuledOuterVisible = !this.invoiceNuledOuterVisible;
    },
    toggleModalInvoiceDelete() {
      this.invoiceDeleteOuterVisible = !this.invoiceDeleteOuterVisible;
    },
    async onOpenDocumentSendDrawer() {
      const { client } = this.invoice;
      if (client && client.email) {
        this.toMails = [client.email];
      }
      this.visibleDocumentSend = true;
    },
    onCloseDocumentSendDrawer() {
      this.visibleDocumentSend = false;
    },
    onCloseDocumentSendEmailHistory() {
      this.visibleDocumentHistoryEmail = false;
    },
    onDocumentSend({ data }) {
      data.append("invoice_id", this.invoice.id);

      this.sendInvoiceEmail({ data });
    }
  },
  components: {
    IconMoreOptionComponent,
    ButtonPrimaryComponent,
    WrapperSendDocumentDrawer,
    PrintInvoice,
    Preview,
    LinkSRI
  }
};
</script>
