<template>
  <div>
    <slot name="label">
      <label class="font-semi-bold m-0 d-block">{{ title }}</label>
    </slot>
    <el-select
      filterable
      v-model="emissionPointId"
      :placeholder="placeholder"
      @change="onChangeSelect"
      :disabled="disabled"
    >
      <el-option
        v-for="point in emissionPoints"
        :key="point.id"
        :label="`${point.user_business_establishment.code} - ${point.code}`"
        :value="point.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import tradeService from "../profile/services/tradeService";

export default {
  name: "SelectEmissionPointsComponent",
  data() {
    return {
      emissionPoints: [],
      emissionPointId: this.setEmissionPointId
    };
  },
  watch: {
    setEmissionPointId(val) {
      this.emissionPointId = val;
    }
  },
  methods: {
    onChangeSelect() {
      this.$emit("onChangeSelect", {
        emissionPointId: this.emissionPointId
      });
    },
    onLoadDefaultEmissionPoint() {
      if (!this.emissionPointId) {
        let filterEmissionPoint = this.emissionPoints.filter(
          el => el.user_business_establishment.is_default === 1
        );
        let isDefault = filterEmissionPoint.find(el => el.is_default === true);
        this.emissionPointId = isDefault.id;
        this.onChangeSelect();
      }
    },
    async getEmissionPoints() {
      await tradeService.getEmissionPoints().then(response => {
        if (response.data.data) {
          this.emissionPoints = response.data.data;
          this.onLoadDefaultEmissionPoint();
        }
      });
    }
  },
  props: {
    title: {
      default: "Puntos de emisión"
    },
    placeholder: {
      default: "Seleccionar"
    },
    setEmissionPointId: {
      default: null
    },
    disabled: {
      default: false
    }
  },
  mounted() {
    this.getEmissionPoints();
    if (this.setEmissionPointId != null) {
      this.emissionPointId = this.setEmissionPointId;
    }
  }
};
</script>

<style lang="sass" scoped></style>
