<template>
  <section class="">
    <div
      v-if="quote"
      class="sale-document-body md__border border-radius bg-color-write p-3 mb-2"
    >
      <div class="sale-document-info">
        <div class="sale-document-description d-flex justify-content-between">
          <TextAvatarComponent :text="lastQuoteVersion.name" class="m-2" />
          <div class="info cursor-point" @click.stop="quoteView">
            <p class="m-0 text-color-primary str-limit-space">
              N° {{ quote.code }}
            </p>
            <p class="m-0 font-bold text-color-primary str-limit-space">
              {{ lastQuoteVersion.name }}
            </p>
            <p class="m-0">
              <small>creado: {{ lastQuoteVersion.created_at }}</small>
            </p>
          </div>
          <div class="sale-document-number">
            <h5 class="h5 font-bold">
              <FormatAmount :amount="lastQuoteVersion.total" />
            </h5>
          </div>
        </div>
      </div>

      <div class="sale-document-actions d-flex justify-content-between align-items-center mt-4">
        <div class="status d-flex">
          <p class="m-0 font-bold text-color-primary">
            <StatusReadDocumentsComponent :status.sync="quote.status" typeStatus="short" />
            <img class="ico-version ml-2" :src="icoVersions" width="15px" alt="Versiones" />
            {{ lastQuoteVersion.version_number }}

            <WrapperTooltipComponent v-if="lastQuoteVersion.last_sent_email_at"
              :content="`Última vez enviado por email ${lastQuoteVersion.last_sent_email_at}`">
              <img class="ml-2" :src="icoSendBlack" width="18px" alt="Email" />
            </WrapperTooltipComponent>
          </p>
        </div>
        <div class="next">
          <QuoteOptionsByStatus
            :status="quote.status"
            :id="quote.id"
            :idVersion="lastQuoteVersion.id"
            @sendDoc="showModalQuoteToInvoice"
            @goPage="goRouter"
          />
        </div>
      </div>
    </div>

    <el-dialog width="35%" title="" :visible.sync="outerVisible" class="">
      <h4 class="h4 text-center font-semi-bold text-break text-color-primary">
        ¿Enviar documento a facturación?
      </h4>

      <SelectEmissionPointsComponent @onChangeSelect="onChangeSelectEmissionPoints" class="my-4" />

      <div class="d-flex justify-content-center align-items-center mt-5">
        <button type="button" @click="outerVisible = false" class="btn md__btn-third-secondary mr-4">
          Cancelar
        </button>
        <ButtonPrimaryComponent title="Enviar" @onSaveClick="sendQuoteToInvoice" />
      </div>
    </el-dialog>
  </section>
</template>

<script>
import TextAvatarComponent from "../../../components/TextAvatar";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import SelectEmissionPointsComponent from "../../../../components/SelectEmissionPoints";
import WrapperTooltipComponent from "../../../../components/WrapperTooltip";
import FormatAmount from "@/components/General/FormatAmount";

import QuoteOptionsByStatus from "./QuoteOptionsByStatus.vue";

import quoteService from "../services/quoteService";
import { icoSendBlack, icoVersions } from "@/services/resources";

export default {
  name: "QuoteCardComponent",
  data() {
    return {
      outerVisible: false,
      userBusinessEPointId: null,
      icoSendBlack,
      icoVersions
    };
  },
  computed: {
    lastQuoteVersion() {
      return this.quote.versions.find((v, index) => index <= 0);
    },
    isSendBilling() {
      return this.quote.status === "draft" || this.quote.status === "pending";
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    showModalQuoteToInvoice() {
      this.outerVisible = true;
    },
    onChangeSelectEmissionPoints({ emissionPointId }) {
      this.userBusinessEPointId = emissionPointId;
    },
    quoteView() {
      this.goRouter("sales.quotes.view", {
        id: this.quote.id,
        idVersion: this.lastQuoteVersion.id
      });
    },
    async sendQuoteToInvoice() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { client } = this.quote;
        const { discount, products } = this.lastQuoteVersion;

        let response = await quoteService
          .sendQuoteToInvoice({
            user_business_client_id: client.id,
            user_business_e_point_id: this.userBusinessEPointId,
            email: client.email,
            discount: discount,
            discount_type: "percentage",
            user_business_quote_id: this.quote.id,
            user_business_quote_v_id: this.lastQuoteVersion.id,
            products: products
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response.data.success) {
          this.outerVisible = false;
          const { id: invoiceId } = response.data.data;
          this.goRouter("sales.invoices.view", {
            id: invoiceId
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TextAvatarComponent,
    ButtonPrimaryComponent,
    StatusReadDocumentsComponent,
    SelectEmissionPointsComponent,
    WrapperTooltipComponent,
    FormatAmount,
    QuoteOptionsByStatus
  },
  props: {
    quote: {
      type: Object,
      default: null
    }
  },
  mounted() { }
};
</script>
