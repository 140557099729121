<template>
  <div
    class="wrapper__card-empty-documents d-flex flex-column justify-content-center align-items-center bg-color-write border-radius p-4 m-4"
  >
    <div class="wrapper__card-empty-image" :style="stylesImage">
      <img :src="icoCash" alt="" width="74" />
    </div>
    <div class="wrapper__card-empty-info my-2">
      <p class="text-center">
        Solicita tu primer <br />
        cobro de servicio
      </p>
    </div>
    <div class="wrapper__card-empty-action">
      <ButtonPrimaryComponent
        title="Realizar cobro"
        @onSaveClick="onPaymentAdd"
      />
    </div>
  </div>
</template>

<script>
import { icoCash, bgOval } from "@/services/resources";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";

export default {
  name: "PaymentEmptyComponent",
  data() {
    return {
      icoCash,
      bgOval,
      stylesImage: {
        backgroundImage: `url(${bgOval})`
      }
    };
  },
  methods: {
    onPaymentAdd() {
      this.$emit("onPaymentAdd");
    }
  },
  components: {
    ButtonPrimaryComponent
  }
};
</script>

<style lang="scss" scoped></style>
