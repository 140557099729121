<template>
  <div>
    <div
      v-if="payment"
      @click.stop="paymentView"
      class="sale-document-body md__border border-radius bg-color-write p-3 mb-2 cursor-point"
    >
      <div class="sale-document-info">
        <div class="sale-document-description d-flex justify-content-between">
          <TextAvatarComponent :text="payment.description" class="m-2" />
          <div class="info">
            <p class="m-0 text-color-primary str-limit-space">
              N° {{ payment.sequence }}
            </p>
            <p class="m-0 font-bold text-color-primary str-limit-space">
              {{ payment.description }}
            </p>
            <p class="m-0">
              <small>
                <span class="">creado: {{ payment.created_at }}</span>
              </small>
            </p>
          </div>
          <div class="sale-document-number">
            <h5 class="h5 font-bold mb-0">
              <FormatAmount :amount="payment.total" />
            </h5>
          </div>
        </div>
      </div>

      <div
        class="sale-document-actions d-flex justify-content-between align-items-center mt-4"
      >
        <div class="status d-flex">
          <img class="mr-1" :src="iconPaylink" width="15px" alt="Pagos" />
          <span class="font-bold text-primary">{{ payment.pay_quantity }}</span>
          <CheckSuccessfully
            class="ml-2"
            :success="payment.is_paid"
            text="Pagada"
          />
          <div class="ml-2 mt-1" v-if="woocomerceId">
            <ImageIconWoocommerce :urlImage="woocomerceId" />
            <small
              ><span class="font-semi-bold mr-2"
                >#{{ woocomerceId }}</span
              ></small
            >
          </div>
        </div>
        <div class="next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { icoCircleChecked, iconPaylink } from "@/services/resources";
import TextAvatarComponent from "../../../components/TextAvatar";
import FormatAmount from "@/components/General/FormatAmount";
import CheckSuccessfully from "@/components/General/CheckSuccessfully";
import ImageIconWoocommerce from "../../../../components/ImageIconWoocommerce";

export default {
  name: "InvoiceCardComponent",
  data() {
    return {
      icoCircleChecked,
      iconPaylink
    };
  },
  computed: {
    woocomerceId() {
      return this.payment?.invoice[0]?.woocomerce_id || null;
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    paymentView() {
      this.goRouter("sales.payments.view", {
        id: this.payment.id
      });
    }
  },
  components: {
    TextAvatarComponent,
    FormatAmount,
    CheckSuccessfully,
    ImageIconWoocommerce
  },
  props: {
    payment: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
